// @flow
import * as React from 'react';
const Dashboard = ({props}) => {

  const valuegetter = (name) => {
    const data =   props.filter((item) => (item.name === name))
    return data
  }
  return(
  <div className="content-wrapper">
    <div className="content-header">
      <div className="container-fluid">
        <div className="row mb-2">
          <div className="col-sm-6">
            <h1 className="m-0">Dashboard</h1>
          </div>
          <div className="col-sm-6">
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item">Home</li>
              <li className="breadcrumb-item active">Dashboard</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <section className="content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-3 col-6">
            <div className="small-box bg-info">
              <div className="inner">
                <h3>{valuegetter('Vehicle')[0].count}</h3>
                <p>Vehicle</p>
                <i className="nav-icon fas fa-truck-monster" />
              </div>
              <div className="icon">
              <i className="nav-icon fas fa-truck-monster" />
              </div>
              <a href={valuegetter('Vehicle')[0].url} className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
            </div>
          </div>
          <div className="col-lg-3 col-6">
            <div className="small-box bg-success">
              <div className="inner">
                <h3>{valuegetter('Vehicle Category')[0].count}</h3>
                <p>Vehicle Category</p>
                <i className="nav-icon fas fa-truck-monster" />
              </div>
              <div className="icon">
                <i className="ion ion-stats-bars" />
              </div>
              <a href="/vehicleCategory" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
            </div>
          </div>

          <div className="col-lg-3 col-6">
            <div className="small-box bg-info">
              <div className="inner">
                <h3>{valuegetter('Users')[0].count}</h3>
                <p>Users</p>
                <i className="nav-icon fas fa-users" />
              </div>
              <div className="icon">
              <i className="nav-icon fas fa-users" />
              </div>
              <a href={valuegetter('Users')[0].url} className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
            </div>
          </div>


          <div className="col-lg-3 col-6">
            <div className="small-box bg-warning">
              <div className="inner">
                <h3>{valuegetter('Shop')[0].count}</h3>
                <p>Shop</p>
                <i class="nav-icon fas fa-store" />
              </div>
              <div className="icon">
              <i class="nav-icon fas fa-store" />
              </div>
              <a href="/shop" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
            </div>
          </div>
          
        </div>
        <div className="row">
        <div className="col-lg-3 col-6">
          <div className="small-box bg-danger">
            <div className="inner">
              <h3>{valuegetter('Orders')[0].count}</h3>
              <p>Orders</p>
              <i class="fa fa-gift" />
            </div>
            <div className="icon">
            <i class="fa fa-gift" />
            </div>
            <a href="/orders" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
          </div>
          </div>
          <div className="col-lg-3 col-6">
            <div className="small-box bg-info">
              <div className="inner">
                <h3>{valuegetter('Products')[0].count}</h3>
                <p>Products</p>
                <i class="nav-icon fab fa-product-hunt" />
              </div>
              <div className="icon">
              <i class="nav-icon fab fa-product-hunt" />
              </div>
              <a href="/product" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
            </div>
          </div>
          <div className="col-lg-3 col-6">
            <div className="small-box bg-success">
              <div className="inner">
                <h3>{valuegetter('Products')[0].count}</h3>
                <p>Product Categories</p>
                <i class="nav-icon fas fa-store" />
              </div>
              <div className="icon">
                <i className="ion ion-stats-bars" />
              </div>
                <a href={valuegetter('Product Categories')[0].url} className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
            </div>
          </div>
          <div className="col-lg-3 col-6">
            <div className="small-box bg-warning">
              <div className="inner">
                <h3>{valuegetter('Shop Category')[0].count}</h3>
                <p>Shop Category</p>
                <i class="nav-icon fab fa-stripe-s"></i>
              </div>
              <div className="icon">
              <i class="nav-icon fab fa-stripe-s"></i>
              </div>
              <a href={valuegetter('Shop Category')[0].url} className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
            </div>
          </div>
          
        </div>
        <div className="row">
        <div className="col-lg-3 col-6">
            <div className="small-box bg-danger">
              <div className="inner">
                <h3>{valuegetter('Product Subcategory')[0].count}</h3>
                <p>Product Subcategory</p>
                <i class="nav-icon fas fa-file-alt" />
              </div>
              <div className="icon">
                <i className="ion ion-pie-graph" />
              </div>
              <a href={valuegetter('Product Subcategory')[0].url} className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
            </div>
          </div>
        <div className="col-lg-3 col-6">
          <div className="small-box bg-info">
            <div className="inner">
              <h3>{valuegetter('Static Pages')[0].count}</h3>
              <p>Static Pages</p>
              <i className="nav-icon fas fa-file-alt" />
            </div>
            <div className="icon">
            <i className="nav-icon fas fa-file-alt" />
            </div>
            <a href={valuegetter('Static Pages')[0].url} className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
          </div>
        </div>

        <div className="col-lg-3 col-6">
          <div className="small-box bg-info">
            <div className="inner">
              <h3>{valuegetter('Contact Us')[0].count}</h3>
              <p>Contact US</p>
              <i class="nav-icon fas fa-address-book" />
            </div>
            <div className="icon">
            <i class="nav-icon fas fa-address-book" />
            </div>
            <a href={valuegetter('Contact Us')[0].url} className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
          </div>
        </div>


        <div className="col-lg-3 col-6">
          <div className="small-box bg-success">
            <div className="inner">
              <h3>{valuegetter('Transactions')[0].count}</h3>
              <p>Transactions</p>
              <i class="nav-icon fas fa-money-check-alt" />
            </div>
            <div className="icon">
            <i class="nav-icon fas fa-money-check-alt" />
            </div>
            <a href={'/transactions'} className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
          </div>
        </div>
        </div>
      </div>
    </section>
  </div>
  )
}
export default Dashboard