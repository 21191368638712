import React, { useState } from "react";
import { Table } from "reactstrap";
import './index.css'
import ReactPaginate from 'react-paginate'

function Shoptable({tabledata,show}) {
  const [pageNumber,setPageNumber] = useState(0)

  const shopHeaders = ["Name", "Description", "Address", "Zip Code","Image"] //"User","Category",
  
  const usersPerPage = 8 
  const pageVisited = pageNumber*usersPerPage
  const diplayusers = tabledata ? tabledata.slice(pageVisited, pageVisited+usersPerPage) : []

  console.log(tabledata)

  const VisualRepresentation = () => {
    return(
      <Table responsive>
        <thead>
          <tr>
            {shopHeaders.map((itm,key) => <th key={key}>{itm}&emsp;&emsp;</th>)}
          </tr>
        </thead>
        <tbody>
        {diplayusers.map((itm,key) => 
          <tr key={key}>
              <td>
               {itm.shopName}
             </td>
             {/* <td>
               {itm.user}
             </td>
             <td>
               {itm.category}
             </td> */}
             <td>
               {itm.description}&emsp;
             </td>
             <td>
               {itm.address}
             </td>
             <td>
               {itm.zipCode}&emsp;
             </td>
             <td>
             <img width="100px" height="60px" src={itm.image} alt="error"/>
             </td>
          </tr>
        )}
      </tbody>
    </Table>
    )
  }
  const changePage = ({selected}) => {
    setPageNumber(selected)
  }
  const pageCount = Math.ceil(tabledata.length / usersPerPage)
  return(
    <React.Fragment>
        <VisualRepresentation />
        <ReactPaginate
          previousLabel={"Prev"}
          nextLabel={"Next"}
          pageCount={pageCount}
          onPageChange={changePage}
          containerClassName={"paginationBttns"}
          previousLinkClassName={"previousBttn"}
          nextLinkClassName={"nextBttn"}
          disabledClassName={"paginationDisabled"}
          activeClassName={"paginationActive"}
        />
      </React.Fragment>
  )
}

export default Shoptable;
