import React, { useState } from "react";
import { Table } from "reactstrap";
import './index.css'
import ReactPaginate from 'react-paginate'

function OrderTable({tabledata,show}) {
  const [pageNumber,setPageNumber] = useState(0)

  const shopHeaders = ["OrderID","ShopId","Shop Name","UserId","User Name","User Type", "Address","Long,Lat","Zipcode","Contact No","Description","Payment type","Payment Status", "Amount", "Orderstatus"] //"User","Category",
  
  const usersPerPage = 8 
  const pageVisited = pageNumber*usersPerPage
  const diplayusers = tabledata ? tabledata.slice(pageVisited, pageVisited+usersPerPage) : []

  const VisualRepresentation = () => {
    return(
      <Table responsive>
        <thead>
          <tr>
            {shopHeaders.map((itm,key) => <th key={key}>{itm}&emsp;&emsp;</th>)}
          </tr>
        </thead>
        <tbody>
        {diplayusers.map((itm,key) => 
          <tr key={key}>
              <td>
               {itm._id}
             </td>
             <td>
               {itm.shop_id}
             </td>
             <td>
               {/* {console.log(itm.ShopName[0].shopName)} */}
               {/* {itm.ShopName[0].shopName} */}
               {itm.ShopName && itm.ShopName.length && itm.ShopName[0].shopName? itm.ShopName[0].shopName : ""}
             </td>
             <td>
               {itm.user}
             </td>
             <td>
               {itm.userName && itm.userName.length && itm.userName[0].firstName ? itm.userName[0].firstName : ""}  {itm.userName && itm.userName.length && itm.userName[0].lastName ? itm.userName[0].lastName :""}
             </td>
             <td>
               {itm.userType}
             </td>
             <td>
               {itm.address}&emsp;
             </td>
             <td>
               {itm.longitude},{itm.latitude}
             </td>
             <td>
               {itm.zipCode}&emsp;
             </td>
             <td>
               {itm.contactNo}
             </td>
             <td>
               {itm.description}&emsp;
             </td>
             <td>
               {itm.paymentType}
             </td>
             <td>
               {itm.paymentStatus}&emsp;
             </td>
             <td>
               {itm.total_amount}
             </td>
             <td>
               {itm.orderStatus}&emsp;
             </td>
             {/* <td>
             <img width="100px" height="60px" src={itm.image} alt="error"/>
             </td> */}
          </tr>
        )}
      </tbody>
    </Table>
    )
  }
  const changePage = ({selected}) => {
    setPageNumber(selected)
  }
  const pageCount = Math.ceil(tabledata.length / usersPerPage)
  return(
    <React.Fragment>
        <VisualRepresentation />
        <ReactPaginate
          previousLabel={"Prev"}
          nextLabel={"Next"}
          pageCount={pageCount}
          onPageChange={changePage}
          containerClassName={"paginationBttns"}
          previousLinkClassName={"previousBttn"}
          nextLinkClassName={"nextBttn"}
          disabledClassName={"paginationDisabled"}
          activeClassName={"paginationActive"}
        />
      </React.Fragment>
  )
}

export default OrderTable;
