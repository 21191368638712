import React, { useEffect, useState } from "react"
import Header from "./header";
import AdminLtSide from "./adminltsidebar";
import { useDispatch, useSelector, batch } from "react-redux";
import './index.css'
import Search from "../Search";
import SubcategoryTable from "../Tablee/subcategorytable";
import {
  deleteSubcategorySuccess,
  fetchallSubCategoryInitiate
} from "../../redux/actionCreators/productSubcategoryActions";
import { makereq, toaster } from "../../redux/sagas/utils";
import { Button } from "reactstrap";
import axios from "axios";

const SubcategoryDisplay = () => {
  const [searchBy, setSearchBy] = useState('')
  const [search, setsearch] = useState('');
  const [searchRes,setsearchRes] = useState('')
  
  // const [searchId,setSearchId] = useState('')

  const baseURL = process.env.REACT_APP_BASE_URL;
  const token = `Bearer ${JSON.parse(localStorage.getItem('userData')).token}`
  var allSubCategorylist = (useSelector(state => state.productSubCategoryState.allSubCategorylist))

  const dispatch = useDispatch();
  useEffect(() => {
    batch(()=>{
      dispatch(fetchallSubCategoryInitiate());
    })
  },[])
  
  const dropdownArray = [
    {
      name: 'Name',
      value: 'name'
    },
    // {
    //   name: 'Subcat Id',
    //   value: 'subCatId'
    // },
    {
      name: 'Cat Id',
      value: 'catId'
    },
  ]
  const handleSubmit = () => {
    if(searchBy.trim() && search.trim()){
      if(searchBy === 'name'){
          const newarr = allSubCategorylist.filter((itm) => itm.name === search)
          if(newarr.length){
            setsearchRes(newarr)
            return
          }
        toaster("error","could not find your searching category")
        return
      }else if(searchBy === 'catId'){
        if(search.trim() !== ''){
          axios.get(`${baseURL}/subCategory/${search}`,{
            headers: {
              Authorization: `Bearer ${JSON.parse(localStorage.getItem('userData')).token}`
            }
          })
          .then(res=> setsearchRes(res.data.data))
          return
        }
        toaster("error","could not find the searching criterion")
        return
      }
    }else{
      toaster("error","Something is missing")
    }
  }
  
  const handleback = () => {
    setsearchRes('')
    setsearch('')
    setSearchBy('')
  }

  const handleSearchDelete = (id) => {
    axios.delete(`${baseURL}/subCategory/${id}`,{
      headers:{
        'Authorization': token
      }
    }).then(res => {
      if(res.status === 200){
        const newSearch = searchRes.filter(itm =>itm._id !== id)
        setsearchRes(newSearch)
        deleteSubcategorySuccess({id})
        toaster("success","Delete Vehicle Category Success")
      }else{
        toaster("error","Error")
      }
    })
  }

  const handleEditSearch = async(newItem) => {
    try {
      const formData = new FormData();
      formData.append('catname', newItem.name);
      formData.append('description', newItem.description);
      formData.append('category', newItem.category);
      formData.append("proSubCatImage", newItem.image, newItem.image.name);
  
      const headers= { 
        'Content-Type': 'multipart/form-data', 
        'Authorization': `Bearer ${JSON.parse(localStorage.getItem('userData')).token}`
      }
  
      const res = await makereq(`subCategory/${newItem.id}`,'put',formData,headers)
      const {data} = res
      toaster('success','Sub Category updated successfully')
    }catch (error) {
      error.message ? toaster('error', error.message) : toaster('error', 'Error Occured')
      console.log(error)
    }
  }

  const handleAdd = async (neWdata) => {
    try {
      const formData = new FormData();
      formData.append('name', neWdata.name);
      formData.append('description', neWdata.description);
      formData.append('category', neWdata.category);
      formData.append("proSubCatImage", neWdata.image, neWdata.image.name);
  
      const headers= { 
        'Content-Type': 'multipart/form-data', 
        'Authorization': `Bearer ${JSON.parse(localStorage.getItem('userData')).token}`
      }
      const res = await makereq(`subCategory`,'post',formData,headers)
      const {data} = res
      if(Object.keys(data).length){
        const newSearchRes = [...searchRes,data]
        setsearchRes(newSearchRes)
        toaster('success','Subcategory added successfully')
      }
    }catch (error) {
      error.message ? toaster('error', error.message) : toaster('error', 'Error Occured')
      console.log(error.message)
    }
  }

  const SearchData = () => {
    return(
      <div className="content">
        <div className="container-fluid">
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <p>Search Results</p>
            <Button
              color="primary"
              style={{ padding: '0px 28px 0px 28px'}}
              onClick={handleback}
            >
              Back
            </Button>
          </div> 
          <SubcategoryTable
            search={true}
            tabledata={searchRes}
            show={"productsubcategory"}
            searchAdd={() => handleAdd()}
            addInSearch={(data) => handleAdd(data)}
            searchDelete={ (id) => handleSearchDelete(id)}
            searchEdit = {(newItem) => handleEditSearch(newItem)}
          />
        </div>
      </div>
    )
  }

  const NormalData = () => {
    return(
      <div className="content">
        <div className="container-fluid">  
          <SubcategoryTable
            show={"productsubcategory"}
            tabledata=
            {
              allSubCategorylist  && allSubCategorylist.length
              ? allSubCategorylist
              : []
            }
          />
        </div>
      </div>
    )
  }

  return(
    <div class="wrapper">
    <Header />
    <AdminLtSide />
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
        <div className="row mb-2">
          <div className="col-sm-6">
            <h1 className="m-0">Sub Category</h1>
          </div>
          <div className="col-sm-3">
            <Search 
              inputstate={search}
              dropdownchange={(value) => setSearchBy(value)}
              dropdownarray={dropdownArray}
              inputchange={(value) => setsearch(value)}
              dropdownstate={searchBy}
              handleSubmit={handleSubmit}
            />
          </div>
        </div>
        </div>
        </div>
        <div className="content">
          <div className="container-fluid">  
            {searchRes.length > 0  ? <SearchData /> : <NormalData />}
          </div>
        </div>
      </div>
    </div>
  )
}
export default SubcategoryDisplay