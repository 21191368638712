import { productActions, shopActions, staticPageActions, vehicleActions } from "../actionTypes";

const intitalState = {
  vehiclelist : [],
  shoplist: [],
  productlist: [],
  privacyPolicy: null,
  termsOfUse: null,
};

export const vehicleReducer = (state = intitalState, action) => {
  const {payload,type} = action
  switch (type) {
    case vehicleActions.FETCH_VEHICLE_INITIATE: {
      return {
        ...state,
      };
    }
    case vehicleActions.FETCH_VEHICLE_SUCCESS:
      return {
        ...state,
        vehiclelist: payload.data,
      };
    case vehicleActions.DELETE_VEHICLE_INITIATE: {
      return {
        ...state,
      };
    }
    case vehicleActions.DELETE_VEHICLE_SUCCESS:{
      const res = state.vehiclelist.filter((itm) => itm._id !== payload.payload)
      return {
        ...state, vehiclelist: res
      }
    }
    case vehicleActions.ADD_VEHICLE_INITIATE: {
      return {
        ...state,
      };
    }
    case vehicleActions.ADD_VEHICLE_SUCCESS:{
      const newObj = {
        _id: payload.data.id,
        image: payload.data.image,
        vehicalName: payload.data.vehicalName,
        description: payload.data.description,
        address: payload.data.address,
        user: payload.data.user,
        category: payload.data.category,
        longitude: payload.data.longitude,
        latitude: payload.data.latitude,
        zipCode: payload.data.zipCode,
        createdAt: payload.data.createdAt
      }
      const newvehiclelist = [...state.vehiclelist,newObj]
      return {
        ...state,
        vehiclelist: newvehiclelist
      }
    }
    case vehicleActions.EDIT_VEHICLE_INITIATE: {
      return {
        ...state,
      };
    }
    case vehicleActions.EDIT_VEHICLE_SUCCESS:{
      const {data} = payload
      const newObj = {
        _id: data.id,
        vehicalName: data.vehicalName,
        description: data.description,
        address: data.address,
        user: data.user,
        category: data.category,
        longitude: data.longitude,
        latitude: data.latitude, 
        image: data.image,
        zipCode: data.zipCode,
        createdAt: data.createdAt
      }
      let index = state.vehiclelist.findIndex( element => {
        if (element._id === newObj._id) {
          return true;
        }
      });
      const newArray = [...state.vehiclelist];
      newArray.splice(index, 1, newObj);
      return {
        ...state,
        vehiclelist: newArray
      }
    }
//  -------------------------Shop Reducers----------------------------------------------
    case shopActions.FETCH_SHOP_INITIATE: {
      return {
        ...state,
      };
    }
    case shopActions.FETCH_SHOP_SUCCESS:
      return {
        ...state,
        shoplist: payload.data,
      };
//  ----------------------------product list fetch actions-------------------------------
    case productActions.FETCH_PRODUCT_INITIATE: {
      return {
        ...state,
      };
    }
    case productActions.FETCH_PRODUCT_SUCCESS:{
      return {
        ...state,
        productlist: payload.data,
      };
    }
//  -----------------------------------------------------------------------------------------
    case staticPageActions.FETCH_PRIVACY_POLICY_INITIATE: {
      return{
        ...state,
      };
    }
    case staticPageActions.FETCH_PRIVACY_POLICY_SUCCESS: {
      console.log(payload)
      return {
        ...state,
        privacyPolicy: payload.data
      }
    }
    case staticPageActions.FETCH_TERMS_OF_USE_INITIATE: {
      return{
        ...state,
      };
    }
    case staticPageActions.FETCH_TERMS_OF_USE_SUCCESS: {
      console.log(payload)
      return {
        ...state,
        termsOfUse: payload.data
      }
    }

    default:
      return {
        ...state,
      };
  }
};
