import {put} from "redux-saga/effects"
import axios from 'axios'
import {
  addVehicleSuccess,
  editVehicleSuccess,
  fetchVehicleSuccess,
  deleteVehicleSuccess,
  fetchTermsOfUseSuccess,
  fetchPrivacyPolicySuccess,
} from "../actionCreators/vehicleActions";
import { makereq, toaster } from "./utils";
import { addproductcategorysuccess } from "../actionCreators/productCategoryActions";

var FormData = require('form-data');
var qs = require('qs')

//const baserurl = `${process.env.REACT_APP_BASE_URL}/vehical`
const baserurl = 'http://3.93.171.186:3001/api/vehical';

const instance = axios.create({
  baseURL: 'http://3.93.171.186:3001/api/',
  timeout: 1000,
});
// const instance = axios.create({
//   baseURL: process.env.REACT_APP_BASE_URL,
//   timeout: 1000,
// });

export function* addVeicleSaga({payload}) {
  console.log(payload, "payload")
  try {
    const id = JSON.parse(localStorage.getItem('userData'))._id

    const headers= { 
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': `Bearer ${JSON.parse(localStorage.getItem('userData')).token}`
    }
    
    const formData = new FormData();
    formData.append('vehicalName', payload.name);
    formData.append('category', payload.category);
    formData.append('address', payload.address);
    formData.append('zipCode', payload.zipcode);
    formData.append('latitude', payload.latitude);
    formData.append('longitude', payload.longitude);
    formData.append('description', payload.description);
    formData.append("vehicalImage", payload.vehicalImage);
    const res  = yield makereq(`vehical/${id}`,'POST',formData,headers)
    console.log(res)
    const {data} = res
    yield put(addVehicleSuccess({data}))
    toaster('success',"Vehicle Added Successfully")

  } 
  catch (error) {
    console.log(error)
  }
}

export function* fetchvehicleSaga({payload}) {
  try {
    const headers= { 
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': `Bearer ${JSON.parse(localStorage.getItem('userData')).token}`
    }
    // const res = yield instance.get('vehical')
    const res = yield makereq('vehical/','get',{},headers)
    console.log(res,"fetfch vechile success response")
    const {data} = res
    yield put(fetchVehicleSuccess({data}))
  }
  catch (error) {
    error.message === 'invalid token...' ? toaster("error", 'please login again') : toaster('error', error.message)
    console.log(error)
  }
}

export function* deleteVehicleSaga({payload}) {
  console.log(payload)
  try {
    const headers = { 
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': `Bearer ${JSON.parse(localStorage.getItem('userData')).token}`
    }
    yield makereq(`vehical/${payload}`,'delete',{},headers)
    yield put(deleteVehicleSuccess({payload}))
  } 
  catch (error) {
    console.log(error)
  }
}

export function* editVehicleSaga({payload}) {
  console.log(payload)
  try {
    const headers = { 
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': `Bearer ${JSON.parse(localStorage.getItem('userData')).token}`
    }
    const name = {}
    const formData = new FormData();
    formData.append('vehicalName', payload.name);
    formData.append('category', payload.category);
    formData.append('address', payload.address);
    formData.append('zipCode', payload.zipcode);
    formData.append('latitude', payload.latitude);
    formData.append('longitude', payload.longitude);
    formData.append('description', payload.description);
    formData.append("vehicalImage", payload.vehicalImage);
    const res = yield makereq(`vehical/${payload.id}`,'POST',formData,headers)
    console.log(res,"response for edit")
    const {data} = res
    yield put(editVehicleSuccess({data}))
  } 
  catch (error) {
    console.log(error)
  }
}
// -------------------------------------------------------------------------------------------------------------------------

export function* fetchPrivacypolicySaga({payload}) {
  try {
    console.log("fetchPrivacyPolicy Saga")
    const headers= { 
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': `Bearer ${JSON.parse(localStorage.getItem('userData')).token}`
    }
    // const res = yield instance.get('vehical')
    const res = yield makereq('static-pages/privacyPolicy','get',{},headers)
    console.log(res,"fetfch privacypolicy response")
    const {data} = res
    yield put(fetchPrivacyPolicySuccess({data}))
  }
  catch (error) {
    error.message === 'invalid token...' ? toaster("error", 'please login again') : toaster('error', error.message)
    console.log(error)
  }
}

export function* fetchTermsOfUseSaga({payload}) {
  try {
    console.log("fetch Terms of use Saga")
    const headers= { 
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': `Bearer ${JSON.parse(localStorage.getItem('userData')).token}`
    }
    // const res = yield instance.get('vehical')
    const res = yield makereq('static-pages/termsOfUse','get',{},headers)
    console.log(res,"fetch terms of use Success response")
    const {data} = res
    yield put(fetchTermsOfUseSuccess({data}))
  }
  catch (error) {
    error.message === 'invalid token...' ? toaster("error", 'please login again') : toaster('error', error.message)
    console.log(error)
  }
}