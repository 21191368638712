import { watcher } from "./watcher"
import createSagaMiddleware from "redux-saga"
import { authReducer } from "./reducer/authreducer"
import { vehicleReducer } from "./reducer/vehiclereducer"
import { shopcategoryReducer } from "./reducer/shopcategory"
import { settingsReducers } from "./reducer/settingsreducers"
import { staticpageReducer } from "./reducer/staticpagereducer"
import {createStore, applyMiddleware, combineReducers} from "redux"
import { vehicleCategoryReducer } from "./reducer/vehiclecategoryreducer"
import { productCategoryReducer } from "./reducer/productcategortreducer"
import { productSubCategoryReducer } from "./reducer/productSubcatreducer"
import { transactionReducer } from "./reducer/transactionreducer"
import { orderReducer } from "./reducer/orderReducer"
import { userReducer } from "./reducer/userReducer"
import { contactUsReducer } from "./reducer/contactusReducer"

const sagaMiddleWare = createSagaMiddleware()

const rootReducer = combineReducers({
  userList: userReducer,
  adminState: authReducer,
  orderState: orderReducer,
  vehicleState: vehicleReducer,
  settingsState: settingsReducers,
  contactUSData: contactUsReducer,
  staticpageState: staticpageReducer,
  transactionState: transactionReducer,
  shopcategoryState: shopcategoryReducer,
  productCategoryState: productCategoryReducer,
  vehicleCategoryState: vehicleCategoryReducer,
  productSubCategoryState: productSubCategoryReducer,
})

const store =  createStore(rootReducer, applyMiddleware(sagaMiddleWare))

sagaMiddleWare.run(watcher)

export default store