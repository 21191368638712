import './index.css'
import Header from "./header";
import Search from '../Search';
import { Button } from 'reactstrap';
import AdminLtSide from "./adminltsidebar";
import { toaster } from '../../redux/sagas/utils';
import React, { useEffect, useState } from "react"
import TransactionTable from '../Tablee/transactionTable';
import { useDispatch, useSelector, batch } from "react-redux";
import { fetchTransactionsInitiate } from '../../redux/actionCreators/transactionActions';

const TransactionDisplay = () => {
  const [searchBy, setSearchBy] = useState('');
  const [search, setsearch] = useState('');
  const [searchRes,setSearchRes] = useState('')
  const {transaction} = useSelector(state => state.transactionState)
  const dispatch = useDispatch();
  useEffect(() => {
    batch(()=>{
      dispatch(fetchTransactionsInitiate());
    })
  },[])
  
  const handleSubmit = () => {
    if(searchBy.trim() && search.trim()){
      if(searchBy === 'transactionId'){
        console.log(searchBy,search)
        const newArr = transaction.filter((itm)=> itm._id === search)
        if(newArr.length){
          setSearchRes(newArr)
          return
        }
      }else if(searchBy === 'receiver'){
        const newArr = transaction.filter((itm)=> itm.trnsaction_to === search)
        if(newArr.length){
          setSearchRes(newArr)
          return
        }
      }else if(searchBy === 'payer'){
        console.log(searchBy,search)
        const newArr = transaction.filter((itm)=> itm.trnsaction_from === search)
        if(newArr.length){
          setSearchRes(newArr)
          return
        }
      }
      toaster("error","could not find anything for your search")
      return
    }
  }
  
  const handleback = () => {
    setSearchRes('')
    setsearch('')
    setSearchBy('')
  }

  const dropdownArray = [
    {
      name: 'Transaction Id',
      value: 'transactionId'
    },
    {
      name: 'Reciever',
      value: 'receiver'
    },
    {
      name: 'Payer',
      value: 'payer'
    }
  ]

  const TableView = () => {
    return(
      <div class="hold-transition skin-blue sidebar-collapse">
        {searchRes &&
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <p>Search Result</p>
          <Button color="primary" onClick={handleback}>Back</Button>
        </div> 
        }
        {
          searchRes
          ? <TransactionTable show={"trnasaction"} tabledata={searchRes}/> 
          : <TransactionTable show={"trnasaction"} tabledata={transaction && transaction.length ? transaction : []}/>
        }
      </div>
    )
  }
  
  return(
    <div class="wrapper">
    <Header />
    <AdminLtSide />
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
        <div className="row mb-2">
          <div className="col-sm-6">
            <h1 className="m-0">Transactions</h1>
          </div>
          <div className="col-sm-3">
            <Search
              inputstate={search}
              dropdownstate={searchBy}
              handleSubmit={handleSubmit}
              dropdownarray={dropdownArray}
              inputchange={(value) => setsearch(value)}
              dropdownchange={(value) => setSearchBy(value)}
            />
          </div>
        </div>
        </div>
        </div>
        <div className="content">
          <div className="container-fluid">
            <TableView />
          </div>
        </div>
      </div>
    </div>
  )
}

export default TransactionDisplay