import './index.css'
import _ from "lodash";
import ModalForm from "../Modal/index";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import ReactPaginate from 'react-paginate';
import { Table, Button } from "reactstrap";
import { deleteproductcategoryinitiate } from "../../redux/actionCreators/productCategoryActions";

function CategoryTable({tabledata,show}) {
  const prodcategoryheaders = ["Name", "Description", "ProCat Image", "Actions"]
  const [modaldata, setmodaldata] = useState({})
  const [pageNumber,setPageNumber] = useState(0)
  const [showmodal, setshowmodal] = useState(false)
  

  function handlecategoryDelete (event,id) {
    event.stopPropagation()
    dispatch(deleteproductcategoryinitiate(id))
  }

  const dispatch = useDispatch()
  const usersPerPage =  8
  const pageVisited = pageNumber*usersPerPage
  const diplayusers = tabledata ? tabledata.slice(pageVisited, pageVisited+usersPerPage) : []

  const VisualRepresentation = () => {
    console.log(tabledata,"Data")
    return(
      <Table responsive>
        <thead>
          <tr>
            { 
            prodcategoryheaders.map((itm,key)=>
            <th key={key}>{itm}&emsp;&emsp;</th>
            )}
            <td><Button color="success" onClick={()=> setshowmodal(true) }>Add</Button></td>
          </tr>
        </thead>
        <tbody>
        {diplayusers.map((itm,key) => 
          <tr key={key}>
            <td>
              {itm.name}
            </td>
            <td>
              {itm.description}&emsp;
            </td>
            <td>
              <img width="100px" height="60px" src={`${itm.image}`} alt="error"/>
            </td>
            <td>
              <Button
                onClick={(event) => clickHandler(event,itm._id,'productsubcategory')}
                color="primary"
                style={{float: "left", marginRight:"10px", marginTop:"10px"}}
                >
                Edit
              </Button>
            </td>
            <td>
              <Button
                onClick={(event) => handlecategoryDelete(event,itm._id)}
                color={"danger"}
                style={{float: "left", marginRight:"10px", marginTop:"10px"}}
                >
                {"Delete"}
              </Button>
            </td>
        </tr>
        )}
        </tbody>
      </Table>
    )
  }
  
  const deactivateModal = () => {
    setmodaldata({})
    setshowmodal(false);
  };

  const clickHandler = (event,id) => {
    event.stopPropagation();
    const formData = _.find(tabledata, (tabledata) => tabledata._id === id);
    setmodaldata(formData);
    setshowmodal(!showmodal)
  }
  const changePage = ({selected}) => {
    setPageNumber(selected)
  }

  const pageCount = Math.ceil(tabledata.length / usersPerPage)
  
  return(
    <React.Fragment>
        {showmodal && <ModalForm show={show} deactivate={deactivateModal} item={modaldata}/>}
        <VisualRepresentation />
        <ReactPaginate
          previousLabel={"Prev"}
          nextLabel={"Next"}
          pageCount={pageCount}
          onPageChange={changePage}
          containerClassName={"paginationBttns"}
          previousLinkClassName={"previousBttn"}
          nextLinkClassName={"nextBttn"}
          disabledClassName={"paginationDisabled"}
          activeClassName={"paginationActive"}
        />
      </React.Fragment>
  )
}

export default CategoryTable;
