import React from "react";
import { useHistory } from "react-router";
import { Button } from "reactstrap";

const Notfound = () => {
  const history = useHistory()
  const StyleMain = {
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  }

  const circle = {
    border: '2px solid green'
  }
 
  return(
    <div style={StyleMain}>
      <div style={{borderRadius: '50px',padding: '40px'}}>
        <h1 style={{textAlign: 'center',  fontSize: '50px'}}>404</h1>
        <p style={{textAlign: 'center', fontSize: '35px'}}>The path you are looking for does not exists please go back or go home</p>
      </div>
      <Button color="primary" onClick={() => history.push('/home')}>Return Home</Button>
    </div>
  )
}

export default Notfound