import { Link } from "react-router-dom"
import { useHistory } from "react-router"
import { useDispatch } from "react-redux"
import { logout } from "../../redux/actionCreators/authenticateActions"
const Header = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const handleLogout = () => {
    dispatch(logout())
    localStorage.clear()
    history.push('/sign-in')
  }

  return(
  <nav className="main-header navbar navbar-expand navbar-white navbar-light">
    <ul className="navbar-nav">
      <li className="nav-item">
        <a className="nav-link" data-widget="pushmenu" role="button"><i className="fas fa-bars" /></a>
      </li>
      <li className="nav-item d-none d-sm-inline-block">
        <Link to="/" className="nav-link">Home</Link>
      </li>
    </ul>
    <ul className="navbar-nav ml-auto">
      <li className="nav-item" onClick={handleLogout}>
        <a className="nav-link" data-slide="true" role="button">
          <i className="far fa-comments" />
          <span className="badge badge-danger navbar-badge">Logout</span>
        </a>
      </li>
      <li className="nav-item">
        <a className="nav-link" data-widget="fullscreen" role="button">
          <i className="fas fa-expand-arrows-alt" />
        </a>
      </li>
      <li className="nav-item">
        <Link to="/admin-settings">
          <span className="nav-link" data-widget="control-sidebar" data-slide="true" role="button">
            <i class="fas fa-user-cog"></i>
          </span>
        </Link>
      </li>
    </ul>
  </nav>
  )
}
export default Header