import React, { useEffect, useLayoutEffect, useState, useReducer } from "react";
import { Col, Button, Input } from "reactstrap";
import { useHistory } from "react-router";
import Navbar from "../navbar";
import './index.css'
import { toaster } from "../../redux/sagas/utils";
import { useDispatch, useSelector } from "react-redux";
import { editSettingsInitiate } from "../../redux/actionCreators/settingsActions";
import { enhancedReducer, isObjEmpty } from "../../redux/sagas/utils";
const initialState = {
  formData: {
    id: '',
    name: '',
    email: '',
    password: '',
    sitename: '',
    smtpemail: '',
    smtppassword: '',
  }
}

const SettingsDisplay = (props) => {

  const [state, updateState] = useReducer(enhancedReducer, initialState)
  const dispatch = useDispatch()
  
  const history = useHistory()

  const {
    id,
    name,
    email,
    password,
    sitename,
    smtpemail,
    smtppassword
  } = state.formData
  
  const handleSubmit = () => {
    const editable = {
      id: id,
      name: name,
      email: email,
      password: password,
      sitename: sitename,
      smtpemail:smtpemail,
      smtppassword: smtppassword
    }
    dispatch(editSettingsInitiate(editable))
  }

  const validate = (e) => {
    e.preventDefault()
    const {id,name,email,password,sitename,smtpemail,smtppassword} = state.formData
    if(!id.trim().length || !name.trim().length || !password.trim().length || !email.trim().length || !sitename.trim().length || !smtpemail.trim().length || !smtppassword.trim().length){
      toaster('warning','Some Fields are missing')
      return
    }
    handleSubmit()
  }
  const handleback = () => {
    history.goBack()
  }
  useLayoutEffect(() => {
    if (!isObjEmpty(props.userInfo)) {
      const data = {
        id: props.userInfo._id,
        name: props.userInfo.name,
        email: props.userInfo.email,
        password: props.userInfo.password,
        sitename: props.userInfo.sitename,
        smtpemail: props.userInfo.smtpemail,
        smtppassword: props.userInfo.smtppassword,
      }
      updateState({
        path: 'formData',
        value: data
      })
    }
  }, [props.userInfo])

  const handleChange = (event, data = null) => {
    const name = data ? data.name : event.target.name
    const value = data ? data.value : event.target.value

    updateState({
      path: `formData.${name}`,
      value: value
    })
  }
  return(
    <div>
      <form onSubmit={validate} className="form_class">
        <div className="header_container">
          <h2 className="form_header" style={{backgroundColor: 'transparent'}}>Admin - Settings</h2>
          <Button onClick={handleback}>Back</Button>
        </div>
        <div className="body_container">
          <div className="row">
            <Col><label>Name</label></Col>
            <Col><Input name="name" type="text" value={name} onChange={handleChange} /></Col>
          </div>
          <div className="row">
            <Col><label>Email</label></Col>
            <Col>
            <Input name="email" type="text" value={email} onChange={handleChange} />
            </Col>
          </div>
          <div className="row">
            <Col><label>Password</label></Col>
            <Col><Input name="password" type="text" value={password} onChange={handleChange}/></Col>
          </div>
          <div className="row">
            <Col><label>Site Name</label></Col>
            <Col><Input name="sitename" type="text" value={sitename} onChange={handleChange}/></Col>
          </div>
          <div className="row">
            <h3>SMTP details</h3>
          </div>
          <div className="row">
            <Col><label>Email</label></Col>
            <Col><Input name="smtpemail" type="text" value={smtpemail} onChange={handleChange}/></Col>
          </div>
          <div className="row">
            <Col><label>Password</label></Col>
            <Col><Input name="smtppassword" type="text" value={smtppassword} onChange={handleChange}/></Col>
          </div>
          <div className="button-container">
            <Button type="submit" color="success">Update Settings</Button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default SettingsDisplay