import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Table, Button } from "reactstrap";
import { deleteVehicleIntiate } from "../../redux/actionCreators/vehicleActions";
import ModalForm from "../Modal/index";
import _ from "lodash";
import './index.css'
import ReactPaginate from 'react-paginate'
import { toggleActivationInitiate } from "../../redux/actionCreators/userActions";

function UserTable({tabledata,show}) {
  const dispatch = useDispatch()
  const [modaldata, setmodaldata] = useState({})
  const [pageNumber,setPageNumber] = useState(0)
  const [showmodal, setshowmodal] = useState(false)
  const vehicleheaders = ["Id","Name","UserType","Email","Device Type","Device Token","Confirm Otp","IsConfirmed","Wallet","Status"] //user, category, "Lat,Long"
  const usersPerPage = 8 
  const pageVisited = pageNumber*usersPerPage
  const diplayusers = tabledata.slice(pageVisited, pageVisited+usersPerPage)
  console.log(tabledata, 'UserTable')
  const VisualRepresentation = () => {
    return(
      <Table responsive>
      <thead>
        <tr>
          {vehicleheaders.map((item,key) => 
            <td key={key}>
              {item}
            </td>
          )}
        </tr>
      </thead>
      <tbody>
      {diplayusers.map((item,key) => (
          <tr key={key}>
            <td>
              {item._id}
            </td>
            <td>
              {item.userName}
            </td>
            <td>
              {item.userType}
            </td>
            <td>
              {item.email}
            </td>
            <td>
              {item.deviceType}
            </td>
            <td>
              {item.deviceToken}
            </td>
            <td>
              {item.confirmOTP}
            </td>
            <td>
              {item.isConfirmed ? 'true' : 'false'}
            </td>
            <td>
              {item.wallet}
            </td>
            <td>
              {item.status ? 'Active' : 'Inactive'}
            </td>
            <td>
              <Button color="danger" onClick={(event) => handleActivation(event,item._id,item.status)}>{item.status ? 'DeActivate' : 'Activate'}</Button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
    )
  }
  
  const deactivateModal = () => {
    setmodaldata({})
    setshowmodal(false);
  };
  
  const handleActivation = (event,id,currentStatus) => {
    event.stopPropagation()
    const payload= {
      id: id,
      status: !currentStatus
    }
    dispatch(toggleActivationInitiate(payload))
  }
  const changePage = ({selected}) => {
    setPageNumber(selected)
  }
  const pageCount = Math.ceil(tabledata.length / usersPerPage)
  return(
    <React.Fragment>
      {showmodal && <ModalForm show={show} deactivate={deactivateModal} item={modaldata}/>}
      <VisualRepresentation />
      <ReactPaginate
        previousLabel={"Prev"}
        nextLabel={"Next"}
        pageCount={pageCount}
        onPageChange={changePage}
        containerClassName={"paginationBttns"}
        previousLinkClassName={"previousBttn"}
        nextLinkClassName={"nextBttn"}
        disabledClassName={"paginationDisabled"}
        activeClassName={"paginationActive"}
      />
    </React.Fragment>
  )
}

export default UserTable;
