import {put} from "redux-saga/effects"
import { fetchProductSuccess, fetchShopSuccess } from "../actionCreators/vehicleActions";
import { shopActions } from "../actionTypes";
import { makereq, toaster } from "./utils";

export function* fetchShopSaga({payload}) {
  console.log("fetching shopActions")
  try {
    const headers= { 
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': `Bearer ${JSON.parse(localStorage.getItem('userData')).token}`
    }
    const res = yield makereq('shop','get',null,headers)
    const {data} = res
    yield put(fetchShopSuccess({data}))
  } 
  catch (error) {
    error.message &&  error.message === "invalid token ..."
    ? toaster("error", "please login agian")
    : toaster("error","Error  Occured")
    console.log(error)
  }
}

export function* fetchProductSaga({payload}) {
  try {
    const headers= { 
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': `Bearer ${JSON.parse(localStorage.getItem('userData')).token}`
    }
    const res = yield makereq('product/search','post',null,headers)
    const data = res.data.payload
    yield put(fetchProductSuccess({data}))
  } 
  catch (error) {
    console.log(error)
    error.message &&  error.message === "invalid token ..."
    ? toaster("error", "please login agian")
    : toaster("error","Error  Occured")
  }
}