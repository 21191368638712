import React, { useEffect } from "react"
import './index.css'
import { useDispatch, useSelector, batch } from "react-redux";
import SettingsDisplay from '../settings/index'
import { fetchSettingsInitiate } from "../../redux/actionCreators/settingsActions";
import Header from "./header";
import AdminLtSide from "./adminltsidebar";

const SettingsDisp = () => {
  const userInfo = useSelector(state => state.settingsState.settingsData)
  
  const dispatch = useDispatch();
  useEffect(() => {
    batch(()=>{
      dispatch(fetchSettingsInitiate());
    })
  },[])
  return(
    <div class="wrapper">
    <Header />
    <AdminLtSide />
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
        <div className="row mb-2">
          <div className="col-sm-6">
            <h1 className="m-0">Settings</h1>
          </div>
        </div>
        </div>
        </div>
        <div className="content">
          <div className="container-fluid">
            <SettingsDisplay userInfo={userInfo}/>
          </div>
        </div>
      </div>
    </div>
  )
}
export default SettingsDisp