import { vehicalCategoryActions } from "../actionTypes";

export const addVehicleCategoryInitiate = (data) => {
  return {
    type: vehicalCategoryActions.ADD_VEHICLE_CATEGORY_INITIATE,
    payload: data,
  };
};

export const addVehicleCategorySuccess = (data) => {
  return {
    type: vehicalCategoryActions.ADD_VEHICLE_CATEGORY_SUCCESS,
    payload: data,
  };
};

export const deleteVehicleCategoryIntiate = (data) => {
  console.log("action initiated")
  return {
    type: vehicalCategoryActions.DELETE_VEHICLE_CATEGORY_INITIATE,
    payload: data,
  };
};

export const deleteVehicleCategorySuccess = (data) => {
  console.log("Last action initiated")
  return {
    type: vehicalCategoryActions.DELETE_VEHICLE_CATEGORY_SUCCESS,
    payload: data,
  };
};

export const fetchVehicleCategoryInitiate = (data) => {
  return {
    type: vehicalCategoryActions.FETCH_VEHICLE_CATEGORY_INITIATE,
    payload: data,
  };
};

export const fetchVehicleCategorySuccess = (data) => {
  return {
    type: vehicalCategoryActions.FETCH_VEHICLE_CATEGORY_SUCCESS,
    payload: data,
  };
};

export const editVehicleCategoryInitiate = (data) => {
  return {
    type: vehicalCategoryActions.EDIT_VEHICLE_CATEGORY_INITIATE,
    payload: data,
  };
};

export const editVehicleCategorySuccess = (data) => {
  console.log(data,"Action")
  return {
    type: vehicalCategoryActions.EDIT_VEHICLE_CATEGORY_SUCCESS,
    payload: data,
  };
};

// export const deleteSearchVehicleCategoryIntiate = (data) => {
//   console.log("action initiated")
//   return {
//     type: vehicalCategoryActions.DELETE_SEARCH_VEHICLE_CATEGORY_INITIATE,
//     payload: data,
//   };
// };

// export const deleteSearchVehicleCategorySuccess = (data) => {
//   return {
//     type: vehicalCategoryActions.DELETE_SEARCH_VEHICLE_CATEGORY_INITIATE,
//     payload: data,
//   };
// };

// export const setSearchVehicleCategoryInitiate = (data) => {
//   return {
//     type: vehicalCategoryActions.SET_SEARCH_VEHICLE_CATEGORY_INITIATE,
//     payload: data,
//   };
// };

// export const setSearchVehicleCategorySuccess = (data) => {
//   return {
//     type: vehicalCategoryActions.SET_SEARCH_VEHICLE_CATEGORY_SUCCESS,
//     payload: data,
//   };
// };

// export const editSearchVehicleCategoryInitiate = (data) => {
//   return {
//     type: vehicalCategoryActions.EDIT_SEARCH_VEHICLE_CATEGORY_INITIATE,
//     payload: data,
//   };
// };

// export const editSearchVehicleCategorySuccess = (data) => {
//   return {
//     type: vehicalCategoryActions.EDIT_SEARCH_VEHICLE_CATEGORY_SUCCESS,
//     payload: data,
//   };
// };
