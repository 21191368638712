import './index.css'
import Header from "./header";
import Search from "../Search";
import AdminLtSide from "./adminltsidebar";
import { toaster } from "../../redux/sagas/utils";
import React, { useEffect, useState } from "react"
import CategoryTable from '../Tablee/categorytable';
import { useDispatch, useSelector, batch } from "react-redux";
import { fetchProductCategoryInitiate } from "../../redux/actionCreators/productCategoryActions";
import { Button } from 'reactstrap';

const ProductCategoryDisplay = () => {
  const [search, setsearch] = useState('');
  const [searchBy, setSearchBy] = useState('');
  const [searchRes,setsearchRes] = useState('');
  const productcategorylist = useSelector(state => state.productCategoryState.productCategorylist)

  const dropdownArray = [
    {
      name: 'Name',
      value: 'name'
    },
    {
      name: 'ID',
      value: 'id'
    },
  ]

  const dispatch = useDispatch();
  useEffect(() => {
    batch(()=>{
      dispatch(fetchProductCategoryInitiate())
    })
  },[])
  
  const handleSubmit = () => {
    console.log(searchBy,search)
    if(searchBy.trim() && search.trim()){
      if(searchBy.trim() && search.trim()){
        if(searchBy === 'name'){
          const newArr = productcategorylist.filter((itm)=> itm.name === search)
          console.log(newArr)
          if(newArr.length){
            setsearchRes(newArr)
            return
          }
        }else if(searchBy === 'id'){
          const newArr = productcategorylist.filter((itm)=> itm._id === search)
          if(newArr.length){
            setsearchRes(newArr)
            return
          }
        }
        toaster("success","could not find anything for your search")
        return
      }
    }
    //dispatch
  }

  
  const handleback = () => {
    setsearchRes('')
    setsearch('')
    setSearchBy('')
  }
  const TableContainer = () => {
    return(
      <div className="content">
        {searchRes
          && 
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <p>Search Results</p>
            <Button onClick={handleback}>
              Back
            </Button>
          </div>
        }
        <div className="container-fluid">
          {
          searchRes 
          ?  <CategoryTable show={"productcategory"} tabledata={searchRes}/>
          :  <CategoryTable show={"productcategory"} tabledata={productcategorylist && productcategorylist.length ? productcategorylist : []}/>
          }
        </div>
      </div>
    )
  }



  return(
    <div class="wrapper">
    <Header />
    <AdminLtSide />
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
        <div className="row mb-2">
          <div className="col-sm-6">
            <h1 className="m-0">Product Category</h1>
          </div>
          <div className="col-sm-3">
            <Search
              inputstate={search}
              dropdownchange={(value) => setSearchBy(value)}
              dropdownarray={dropdownArray}
              inputchange={(value) => setsearch(value)}
              dropdownstate={searchBy}
              handleSubmit={handleSubmit}
            />
          </div>
        </div>
        </div>
        </div>
        <TableContainer />
      </div>
    </div>
  )
}
export default ProductCategoryDisplay