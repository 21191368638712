// fetchproductsubcategory
import {put} from "redux-saga/effects"
import { fetchTransactionsSuccess } from "../actionCreators/transactionActions";
import { makereq, toaster } from "./utils";

export function* fetchAllTransactionsSaga({payload}) {
  try {
    const headers = { 
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': `Bearer ${JSON.parse(localStorage.getItem('userData')).token}`
    }
    const res = yield makereq(`transaction/transactionHistory`,'post',{},headers)
    console.log(res)
    const {data} = res
    yield put(fetchTransactionsSuccess({data}))
  }catch (error) {
    error.message ? toaster('error', error.message) : toaster('error', 'Error Occured')
    console.log(error)
  }
}
