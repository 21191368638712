import React from "react";
import Navbar from "../navbar";
import { useState,useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import './index.css'
import { signUpInit } from "../../redux/actionCreators/authenticateActions";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure()

const Register = () => {
  const [username, setUserName] = useState('');
  const [email, setemail] = useState('');
  const [password, setpassword] = useState('');

  const loggedin = useSelector(state => state.adminState.loggedin)
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    if(loggedin){
      history.push("/")
    }
  },[loggedin])

  const handleSubmit = (e) => {
    const data = {
      userName: username,
      email: email,
      password: password
    }
    dispatch(signUpInit(data))
    setpassword('')
    setUserName('')
    setemail('')
  }
  const validate = (e) => {
    e.preventDefault()
    if(username !== '' && email !== '' && password !== ''){
      handleSubmit()
      return
    }
    toast('Please fill the empty fields')
  }
    
  return(
    <div>
      <Navbar />
      <div className="main-div">
      <form onSubmit={validate} className="example_2">
        <h2 className="form_header">Register</h2>
          <label>Name</label>
          <input
            type="text"
            className="form-control"
            placeholder="User Name"
            onChange={(event) => setUserName(event.target.value)}
            value={username}
          />
          <label>Email</label>
          <input
            type="email"
            className="form-control"
            placeholder="Enter Email"
            onChange={(event) => setemail(event.target.value)}
            value={email}
          />
          <label>Password</label>
          <input
            type="password"
            className="form-control"
            placeholder="Enter password"
            onChange={(event) => setpassword(event.target.value)}
            value={password}
          />
        <button
          type="submit"
          className="btn btn-dark btn-lg btn-block"
        >
          Sign in
        </button>
      </form>
      </div>
    </div>
  )
}

export default Register