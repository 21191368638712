import React, { useState } from "react";
import { Table } from "reactstrap";
import ModalForm from "../Modal/index";
import './index.css'
import ReactPaginate from 'react-paginate'

function ProductTable({tabledata,show}) {
  const [showmodal, setshowmodal] = useState(false)
  const [modaldata, setmodaldata] = useState({})
  const [pageNumber,setPageNumber] = useState(0)
  
  const productheaders = ["Name", "Description", "Live price", "Online price", "Product Image"] //"Category", "Sub Category", "Shop",

  const usersPerPage = 8 
  const pageVisited = pageNumber*usersPerPage
  const diplayusers = tabledata ? tabledata.slice(pageVisited, pageVisited+usersPerPage) : []

  console.log(tabledata)

  const VisualRepresentation = () => {
    return(
      <Table responsive>
      <thead>
        <tr>
        {productheaders.map((itm,key)=>
        <th key={key}>{itm}&emsp;&emsp;</th>
        )}
        </tr>
      </thead>
      <tbody>
      {diplayusers.length > 0  && diplayusers.map((itm,key) => 
          <tr key={key} style={{cursor: 'pointer'}}>
              <td>
                {itm.name}
              </td>
              {/* <td>
                {itm.category}
              </td>
              <td>
                {itm.subcategory}
              </td>
              <td>
                {itm.shop} 
              </td>*/}
              <td>
                {itm.description}&emsp;
              </td>
              {/* <td>
                {itm.quantity}
              </td> */}
              <td>
                {itm.live_price}
              </td>
              <td>
                {itm.online_price}
              </td>
              {/* <td>
                {itm.online_delvery}
              </td>
              <td>
                {itm.live_delvery}
              </td>
              <td>
                {itm.colours}
              </td> */}
              <td>
              <img width="100px" height="60px" src={itm.images[0].image} alt="error"/>
              </td>
          </tr>
        )}
      </tbody>
    </Table>
    )
  }
  
  const deactivateModal = () => {
    setmodaldata({})
    setshowmodal(false);
  };
  const changePage = ({selected}) => {
    setPageNumber(selected)
  }
  const pageCount = Math.ceil(tabledata.length / usersPerPage)
  return(
    <React.Fragment>
        {showmodal && <ModalForm show={show} deactivate={deactivateModal} item={modaldata}/>}
        <VisualRepresentation />
        <ReactPaginate
          previousLabel={"Prev"}
          nextLabel={"Next"}
          pageCount={pageCount}
          onPageChange={changePage}
          containerClassName={"paginationBttns"}
          previousLinkClassName={"previousBttn"}
          nextLinkClassName={"nextBttn"}
          disabledClassName={"paginationDisabled"}
          activeClassName={"paginationActive"}
        />
      </React.Fragment>
  )
}

export default ProductTable;
