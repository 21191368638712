import { userActions } from "../actionTypes";

export const getAllUsersInitiate = (data) => {
  return {
    type: userActions.GET_ALL_USERS_INITIATE,
    payload: data,
  };
};

export const getAllUsersSuccess = (data) => {
  console.log(data,"IMOP")
  return {
    type: userActions.GET_ALL_USERS_SUCCESS,
    payload: data,
  };
};

export const toggleActivationInitiate = (data) => {
  console.log("action initiated",data,"gddg")
  return {
    type: userActions.SET_USER_INITIATE,
    payload: data,
  };
};

export const toggleActivationSuccess = (data) => {
  console.log("Last action initiated")
  return {
    type: userActions.SET_USER_SUCCESS,
    payload: data,
  };
};

export const getSearchedUserInitiate = (data) => {
  return {
    type: userActions.SEARCH_USERS_INITIATE,
    payload: data,
  };
};

export const getSearchedUserSuccess = (data) => {
  return {
    type: userActions.SEARCH_USERS_SUCCESS,
    payload: data,
  };
};