export const authentication = {
  LOGIN_INIT: "LOGIN_INIT",
  LOGIN_FAILED: "LOGIN_FAILED",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  SIGNUP_INIT: "SIGNUP_INIT",
  LOGOUT: "LOGOUT",
}

export const userActions = { 
  GET_ALL_USERS_INITIATE: 'GET_ALL_USERS_INITIATE',
  GET_ALL_USERS_SUCCESS: 'GET_ALL_USERS_SUCCESS',
  SEARCH_USERS_INITIATE: 'SEARCH_USER_INITIATE',
  SEARCH_USERS_SUCCESS: 'SEARCH_USER_SUCCESS',
  SET_USER_INITIATE: 'SET_USER_INITIATE',
  SET_USER_SUCCESS: 'SET_USER_SUCCES',
}

export const contactUSAction = {
  FETCH_CONTACT_US_INITIATE: 'FETCH_CONTACT_US_INITIATE',
  FETCH_CONTACT_US_SUCCESS: 'FETCH_CONTACT_US_SUCCESS',
  EDIT_CONTACT_US_INITIATE: 'EDIT_CONTACT_US_INITIATE',
  EDIT_CONTACT_US_SUCCESS: 'EDIT_CONTACT_US_SUCCESS',
}

export const staticPageActions = {
  DELETE_STATIC_PAGE_INITIATE: 'DELETE_STATIC_PAGE_INITIATE',
  DELETE_STATIC_PAGE_SUCCESS: 'DELETE_STATIC_PAGE_SUCCESS',
  ADD_STATIC_PAGE_INITIATE: 'ADD_STATIC_PAGE_INITIATE',
  ADD_STATIC_PAGE_SUCCESS: 'ADD_STATIC_PAGE_SUCCESS',
  FETCH_STATIC_PAGE_INITIATE: 'FETCH_STATIC_PAGE_INITIATE',
  FETCH_STATIC_PAGE_SUCCESS: 'FETCH_STATIC_PAGE_SUCCESS',
  EDIT_STATIC_PAGE_INITIATE: 'EDIT_STATIC_PAGE_INITIATE',
  EDIT_STATIC_PAGE_SUCCESS: 'EDIT_STATIC_PAGE_SUCCESS',

  FETCH_PRIVACY_POLICY_INITIATE: 'FETCH_PRIVACY_POLICY_INITIATE',
  FETCH_PRIVACY_POLICY_SUCCESS: 'FETCH_PRIVACY_POLICY_SUCCESS',
  FETCH_TERMS_OF_USE_INITIATE: 'FETCH_TERMS_OF_USE_INITIATE',
  FETCH_TERMS_OF_USE_SUCCESS: 'FETCH_TERMS_OF_USE_SUCCESS'
}

export const settingsActions = {
  FETCH_SETTINGS_INITIATE: 'FETCH_SETTINGS_INITIATE',
  FETCH_SETTINGS_SUCCESS: 'FETCH_SETTINGS_SUCCESS',
  EDIT_SETTINGS_INITIATE: 'EDIT_SETTINGS_INITIATE',
  EDIT_SETTINGS_SUCCESS: 'EDIT_SETTINGS_SUCCESS',
}

export const vehicalCategoryActions = {
  ADD_VEHICLE_CATEGORY_INITIATE: "ADD_VEHICLE_CATEGORY_INITIATE",
  ADD_VEHICLE_CATEGORY_SUCCESS: "ADD_VEHICLE_CATEGORY_SUCCESS",

  DELETE_VEHICLE_CATEGORY_INITIATE: "DELETE_VEHICLE_CATEGORY_INITIATE",
  DELETE_VEHICLE_CATEGORY_SUCCESS: "DELETE_VEHICLE_CATEGORY_SUCCESS",

  EDIT_VEHICLE_CATEGORY_INITIATE: "EDIT_VEHICLE_CATEGORY_INITIATE",
  EDIT_VEHICLE_CATEGORY_SUCCESS: "EDIT_VEHICLE_CATEGORY_SUCCESS",

  FETCH_VEHICLE_CATEGORY_INITIATE: "FETCH_VEHICLE_CATEGORY_INITIATE",
  FETCH_VEHICLE_CATEGORY_SUCCESS: "FETCH_VEHICLE_CATEGORY_SUCCESS",

  // SET_SEARCH_VEHICLE_CATEGORY_INITIATE: 'SET_SEARCH_VEHICLE_CATEGORY_INITIATE',
  // SET_SEARCH_VEHICLE_CATEGORY_SUCCESS: 'SET_SEARCH_VEHICLE_CATEGORY_SUCCESS',

  // EDIT_SEARCH_VEHICLE_CATEGORY_INITIATE: 'EDIT_SEARCH_VEHICLE_CATEGORY_INITIATE',
  // EDIT_SEARCH_VEHICLE_CATEGORY_SUCCESS: 'EDIT_SEARCH_VEHICLE_CATEGORY_SUCCESS',

  // DELETE_SEARCH_VEHICLE_CATEGORY_INITIATE: 'DELETE_SEARCH_VEHICLE_CATEGORY_INITIATE',
  // DELETE_SEARCH_VEHICLE_CATEGORY_SUCCESS: 'DELETE_SEARCH_VEHICLE_CATEGORY_SUCCESS',
}

export const vehicleActions = {
  ADD_VEHICLE_INITIATTE: "ADD_VEHICLE_INITIATE",
  ADD_VEHICLE_SUCCESS: "ADD_VEHICLE_SUCCESS",
  DELETE_VEHICLE_INITIATE: "DELETE_VEHICLE_INITIATE",
  DELETE_VEHICLE_SUCCESS: "DELETE_VEHICLE_SUCCESS",
  EDIT_VEHICLE_INITIATE: "EDIT_VEHICLE_INITIATE",
  EDIT_VEHICLE_SUCCESS: "EDIT_VEHICLE_SUCCESS",
  FETCH_VEHICLE_INITIATE: "FETCH_VEHICLE_INITIATE",
  FETCH_VEHICLE_SUCCESS: "FETCH_VEHICLE_SUCCESS",
}

export const shopcategoryActions = {
  ADD_SHOPCATEGORY_INITIATTE: "ADD_SHOPCATEGORY_INITIATE",
  ADD_SHOPCATEGORY_SUCCESS: "ADD_SHOPCATEGORY_SUCCESS",
  DELETE_SHOPCATEGORY_INITIATE: "DELETE_SHOPCATEGORY_INITIATE",
  DELETE_SHOPCATEGORY_SUCCESS: "DELETE_SHOPCATEGORY_SUCCESS",
  EDIT_SHOPCATEGORY_INITIATE: "EDIT_SHOPCATEGORY_INITIATE",
  EDIT_SHOPCATEGORY_SUCCESS: "EDIT_SHOPCATEGORY_SUCCESS",
  FETCH_SHOPCATEGORY_INITIATE: "FETCH_SHOPCATEGORY_INITIATE",
  FETCH_SHOPCATEGORY_SUCCESS: "FETCH_SHOPCATEGORY_SUCCESS",
}

export const shopActions = {
  FETCH_SHOP_INITIATE: 'FETCH_SHOP_INITIATE',
  FETCH_SHOP_SUCCESS: 'FETCH_SHOP_SUCCESS'
}

export const productActions = {
  FETCH_PRODUCT_INITIATE: 'FETCH_PRODUCT_INITIATE',
  FETCH_PRODUCT_SUCCESS: 'FETCH_PRODUCT_SUCCESS'
}

export const productCategoryActions = {
  FETCH_PRODUCTCATEGORY_INITIATE: 'FETCH_PRODUCTCATEGORY_INITIATE',
  FETCH_PRODUCTCATEGORY_SUCCESS: 'FETCH_PRODUCTCATEGORY_SUCCESS',
  DELETE_PRODUCTCATEGORY_INITIATE: 'DELETE_PRODUCTCATEGORY_INITIATE',
  DELETE_PRODUCTCATEGORY_SUCCESS: 'DELETE_PRODUCTCATEGORY_SUCCESS',
  ADD_PRODUCTCATEGORY_INITIATE: 'ADD_PRODUCTCATEGORY_INITIATE',
  ADD_PRODUCTCATEGORY_SUCCESS: 'ADD_PRODUCTCATEGORY_SUCCESS.',
  EDIT_PRODUCTCATEGORY_INITIATE: 'EDIT_PRODUCTCATEGORY_INITIATE',
  EDIT_PRODUCTCATEGORY_SUCCESS: 'EDIT_PRODUCTCATEGORY_SUCCESS'
}

export const productSubcategoryActions = {
  FETCH_ALLSUBCATEGORY_INITIATE: 'FETCH_ALLSUBCATEGORY_INITIATE',
  FETCH_ALLSUBCATEGORY_SUCCESS: 'FETCH_ALLSUBCATEGORY_SUCCESS',
  FETCH_PRODUCTSUBCATEGORY_INITIATE: 'FETCH_PRODUCTSUBCATEGORY_INITIATE',
  FETCH_PRODUCTSUBCATEGORY_SUCCESS: 'FETCH_PRODUCTSUBCATEGORY_SUCCESS',
  DELETE_PRODUCTSUBCATEGORY_INITIATE: 'DELETE_PRODUCTSUBCATEGORY_INITIATE',
  DELETE_PRODUCTSUBCATEGORY_SUCCESS: 'DELETE_PRODUCTSUBCATEGORY_SUCCESS',
  EDIT_PRODUCTSUBCATEGORY_INITIATE: 'EDIT_PRODUCTSUBCATEGORY_INITIATE',
  EDIT_PRODUCTSUBCATEGORY_SUCCESS: 'EDIT_PRODUCTSUBCATEGORY_SUCCESS',
  ADD_PRODUCTSUBCATEGORY_INITIATE: 'ADD_PRODUCTSUBCATEGORY_INITIATE',
  ADD_PRODUCTSUBCATEGORY_SUCCESS: 'ADD_PRODUCTSUBCATEGORY_SUCCESS'
}

export const transactionActions = {
  FETCH_TRANSACTIONS_INITIATE: 'FETCH_TRANSACTIONS_INITIATE',
  FETCH_TRANSACTIONS_SUCCESS: 'FETCH_TRANSACTIONS_SUCCESS',
}

export const orderActions = {
  FETCH_ORDER_INITIATE: 'FETCH_ORDER_INITIATE',
  FETCH_ORDER_SUCCESS: 'FETCH_ORDER_SUCCESS'
}