import './index.css'
import Header from "./header";
import Dashboard from "./dashboard";
import React, { useEffect } from "react"
import AdminLtSide from "./adminltsidebar";
import { useDispatch, useSelector, batch } from "react-redux";
import { getAllUsersInitiate } from '../../redux/actionCreators/userActions';
import { fetchOrdersInitiate } from '../../redux/actionCreators/orderActions';
import { fetchSettingsInitiate } from "../../redux/actionCreators/settingsActions";
import { fetchstaticPageInitiate } from "../../redux/actionCreators/staticPageActions";
import { fetchTransactionsInitiate } from '../../redux/actionCreators/transactionActions';
import { fetchShopCategoryInitiate } from "../../redux/actionCreators/shopcategoryActions";
import { fetchProductCategoryInitiate } from "../../redux/actionCreators/productCategoryActions";
import { fetchVehicleCategoryInitiate } from "../../redux/actionCreators/vehicalCategoryActions";
import { fetchallSubCategoryInitiate } from "../../redux/actionCreators/productSubcategoryActions";
import { fetchProductInitiate, fetchShopInitiate, fetchVehicleInitiate } from "../../redux/actionCreators/vehicleActions";


const Home = () => {
  const {vehiclelist} = useSelector(state=> state.vehicleState)
  const {transaction} = useSelector(state => state.transactionState)
  const shoplist = useSelector(state => state.vehicleState.shoplist)
  const {staticpagelist} = useSelector(state => state.staticpageState)
  const {orders} = useSelector(state => state.orderState)
  const productlist = useSelector(state => state.vehicleState.productlist)
  const {vehicleCategorylist} = useSelector(state => state.vehicleCategoryState)
  const shopcategorylist = useSelector(state => state.shopcategoryState.shopcategorylist)
  const productcategorylist = useSelector(state => state.productCategoryState.productCategorylist)
  const allSubCategorylist = (useSelector(state => state.productSubCategoryState.allSubCategorylist))
  const alluserlist = useSelector(state => state.userList.userList)

  console.log(alluserlist)
  const moduleArr = [
    {
      name: 'Vehicle',
      count:  vehiclelist ? vehiclelist.length : 0,
      url: '/vehicle'
    },
    {
      name: 'Vehicle Category',
      count:  vehicleCategorylist ? vehicleCategorylist.length : 0,
      url: '/vehicleCategory'
    },
    {
      name: 'Users',
      count: alluserlist ? alluserlist.length : 0,
      url: '/users'
    },
    {
      name: 'Shop',
      count: shoplist ? shoplist.length : 0,
      url: '/shop'
    },
    {
      name: 'Orders',
      count: orders? orders.length : 0,
      url: 'order'
    },
    {
      name: 'Products',
      count: productlist ? productlist.length : 0,
      url: '/product'
    },
    {
      name: 'Product Categories',
      count: productcategorylist ? productcategorylist.length : productcategorylist,
      url: '/productcategory'
    },
    {
      name: 'Product Subcategory',
      count: allSubCategorylist ? allSubCategorylist.length : 0,
      url: '/productsubcategory'
    },
    {
      name: 'Shop Category',
      count: shopcategorylist ? shopcategorylist.length : 0,
      url: '/shopcategory'
    },
    {
      name: 'Static Pages',
      count: staticpagelist ? staticpagelist.length : staticpagelist,
      url: '/static-pages'
    },
    {
      name: 'Contact Us',
      count: 1,
      url: '/contactUS'
    },
    {
      name: 'Transactions',
      count: transaction ? transaction.length : 0,
      url: '/transactions'
    }
  ]

  const dispatch = useDispatch();
  useEffect(() => {
    batch(()=>{
      dispatch(fetchShopInitiate())
      dispatch(fetchOrdersInitiate())
      dispatch(getAllUsersInitiate())
      dispatch(fetchProductInitiate())
      dispatch(fetchVehicleInitiate())
      dispatch(fetchSettingsInitiate())
      dispatch(fetchstaticPageInitiate())
      dispatch(fetchShopCategoryInitiate())
      dispatch(fetchTransactionsInitiate())
      dispatch(fetchallSubCategoryInitiate())
      dispatch(fetchallSubCategoryInitiate())
      dispatch(fetchProductCategoryInitiate())
      dispatch(fetchVehicleCategoryInitiate());
    })
  },[])
  
  return(
    <div class="wrapper">
      <Header />
      <AdminLtSide />
      <Dashboard props={moduleArr} />
    </div>
  )
}

export default Home