import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { stringvalidator } from '../../redux/sagas/utils';
import { Button, Form, FormGroup, Label, Row, Input, Col } from 'reactstrap';
import { addVehicleInitiate, editVehicleInitiate } from '../../redux/actionCreators/vehicleActions';
import { addShopCategoryInitiate, editShopCategoryInitiate } from '../../redux/actionCreators/shopcategoryActions';
import { addSubcategoryInitiate, editSubcategoryInitiate } from '../../redux/actionCreators/productSubcategoryActions';
import { addVehicleCategoryInitiate, editVehicleCategoryInitiate } from '../../redux/actionCreators/vehicalCategoryActions';
import { addproductcategoryinitiate, editproductcategoryinitiate } from '../../redux/actionCreators/productCategoryActions';

function AddEditForm(props) {
  const dispatch = useDispatch()
  const[vehicleform, setVehicleform] = useState({
    id: '',
    name: '',
    zipcode: '',
    address: '',
    category: '',
    latitude: '',
    longitude: '',
    description: '',
    vehicalImage: undefined
  })
  const[vehicleCatform, setVehicleCatform] = useState({
    id: '',
    name: '',
    description: '',
  })
  const[shopcategoryform, setshopcategoryform] = useState({
    id: '',
    name: '',
    description: '',
  })
  const[productcatform, setProductcatform] = useState({
    id:'',
    name: '',
    image: null,
    description: '',
  })
  const[subcatogeryform, setsubcatogeryform] = useState({
    id: '',
    name: '',
    image: null,
    category:'',
    description: '',
  })

  const validator = () => {
    if(props.formtype === 'vehicle'){
      const name = stringvalidator(vehicleform.name)
      const address = stringvalidator(vehicleform.address)
      const zipcode = stringvalidator(vehicleform.zipcode)
      const latitude = stringvalidator(vehicleform.latitude)
      const category = stringvalidator(vehicleform.category)
      const longitude = stringvalidator(vehicleform.longitude)
      const description = stringvalidator(vehicleform.description)
      console.log(vehicleform)
      if(!name || !description || !category || !address || !zipcode || !latitude || !longitude ){
        toast('Please fill the form compleately')
        return false
      }
      return true
    }else if(props.formtype === 'vehicleCategory'){
      const name = stringvalidator(vehicleCatform.name)
      const description = stringvalidator(vehicleCatform.description)
      if(name === false || description === false){
        toast('Please fill the form compleately')
        return false
      }
      return true
    }else if(props.formtype === 'shopcategory'){
      const name = stringvalidator(shopcategoryform.name)
      const description = stringvalidator(shopcategoryform.description)
      if(name === false || description === false){
        toast('Please fill the form compleately')
        return false
      }
      return true
    }else if(props.formtype === 'productcategory'){
      const name = stringvalidator(productcatform.name)
      const description = stringvalidator(productcatform.description)
      if(name === false || description === false || !productcatform.image){
        toast('Please fill the form compleately')
        return false
      }
      return true
    }else if(props.formtype === 'productsubcategory'){
      const name = stringvalidator(subcatogeryform.name)
      const category = stringvalidator(subcatogeryform.category)
      if(name === false || category === false || !subcatogeryform.image){
        toast('Please fill the form compleately')
        return false
      }
      return true
    }

  }
  
  const onChange = (e) => {
    if(props.formtype === 'vehicleCategory'){
      setVehicleCatform({
        ...vehicleCatform,
        [e.target.name]: e.target.value
      })
      return
    }else if(props.formtype === 'vehicle'){
      if(e.target.name === 'vehicalImage'){
        setVehicleform({
          ...vehicleform,
          vehicalImage: e.target.files[0]
        })
        return
      }
      setVehicleform({
        ...vehicleform,
        [e.target.name]: e.target.value
      })
      return
    }
    else if(props.formtype === 'shopcategory'){
      setshopcategoryform({
        ...shopcategoryform,
        [e.target.name]: e.target.value
      })
      return
    }else if(props.formtype === 'productcategory'){
      if(e.target.name === 'proCatImage'){
        setProductcatform({
          ...productcatform,
          image: e.target.files[0]
        })
        return
      }
      setProductcatform({
        ...productcatform,
        [e.target.name]: e.target.value
      })
      return
    }else if(props.formtype === 'productsubcategory'){
      if(e.target.name === 'proSubCatImage'){
        setsubcatogeryform({
          ...subcatogeryform,
          image: e.target.files[0]
        })
        return
      }
      setsubcatogeryform({

        ...subcatogeryform,
        [e.target.name]: e.target.value
      })
      return
    }
  }
  
  const submitFormAdd = e => {
    e.preventDefault()
    console.log(props.formtype, vehicleCatform)
    const next = validator();
    if(next === false){
      return
    }
    if(props.formtype === 'vehicleCategory'){
      dispatch(addVehicleCategoryInitiate(vehicleCatform))
      props.toggle()
      return
    }
    if(props.formtype === 'vehicle'){
      dispatch(addVehicleInitiate(vehicleform))
    }
    if(props.formtype === 'productcategory'){
      dispatch(addproductcategoryinitiate(productcatform))
      props.toggle()
      return
    }
    if(props.formtype === 'shopcategory'){
      dispatch(addShopCategoryInitiate(shopcategoryform))
      props.toggle()
      return
    }     
    if(props.formtype === 'productsubcategory'){
      dispatch(addSubcategoryInitiate(subcatogeryform))
      props.toggle()
      return     
    }
  }


  const submitFormEdit = e => {
    e.preventDefault()
    if(props.formtype === 'vehicleCategory'){
      const newItem = {
        id: vehicleCatform.id,
        name: vehicleCatform.name,
        description: vehicleCatform.description
      }
      dispatch(editVehicleCategoryInitiate(newItem))
      props.toggle()
      return
    }else if(props.formtype === 'vehicle'){
      dispatch(editVehicleInitiate(vehicleform))
    }else if(props.formtype === 'shopcategory'){
      if(props.search){
        props.searchEdit(shopcategoryform)
        return
      }
      dispatch(editShopCategoryInitiate(shopcategoryform))
      props.toggle()
      return
      // dispatch(editShopCategoryInitiate(shopcategoryform))
      // props.toggle()
      // return
    }else if(props.formtype === 'productcategory'){
      dispatch(editproductcategoryinitiate(productcatform))
      props.toggle()
      return
    }
    else if(props.formtype === 'productsubcategory'){
      console.log(subcatogeryform)
      dispatch(editSubcategoryInitiate(subcatogeryform))
      props.toggle()
      return
    }
  }
    
    useEffect(() => {
      if(props.item){
        if(props.formtype === 'vehicleCategory'){
          setVehicleCatform({
            ...vehicleCatform,
            id: props.item._id,
            name: props.item.name,
            description: props.item.description
          })
        }else if(props.formtype === 'vehicle'){
          setVehicleform({
            ...vehicleform,
            id: props.item._id,
            name: props.item.vehicalName,
            description: props.item.description,
            category: props.item.category,
            address: props.item.address,
            latitude: props.item.latitude,
            longitude: props.item.longitude,
            zipcode: props.item.zipCode
          })
        }else if(props.formtype === 'shopcategory'){
          setshopcategoryform({
            ...shopcategoryform,
            id: props.item._id,
            name: props.item.name,
            description: props.item.description
          })
        }else if(props.formtype === 'productcategory'){
          setProductcatform({
            ...productcatform,
            id: props.item._id,
            name: props.item.name,
            image: props.item.image,
            description: props.item.description,
          })
        }
        else if(props.formtype === 'productsubcategory'){
          setsubcatogeryform({
            ...subcatogeryform,
            id: props.item._id,
            name: props.item.name,
            image: props.item.image,
            category: props.item.category,
            description: props.item.description,
          })
        }
      }
    }, [])
        
  if(props.formtype === 'vehicle'){
  return(
    <Form onSubmit={Object.entries(props.item).length !== 0 ? submitFormEdit : submitFormAdd}>
      <Row>
      <Col md={6}>
      <FormGroup>
        <Label for="first">Name</Label>
        <Input type="text" name="name" id="first" onChange={onChange} value={vehicleform.name} />
      </FormGroup>
      </Col>
      <Col md={6}>
      <FormGroup>
        <Label for="first">Description</Label>
        <Input type="text" name="description" id="first" onChange={onChange} value={vehicleform.description} />
      </FormGroup>
      </Col>
      </Row>
      <Row>
      <Col md={6}>
      <FormGroup>
        <Label for="fist">Category</Label>
        <Input type="text" name="category" id="first" onChange={onChange} value={vehicleform.category} />
      </FormGroup>
      </Col>
      <Col>
        <FormGroup>
          <Label for="first">Address</Label>
          <Input type="text" name="address" id="first" onChange={onChange} value={vehicleform.address} />
        </FormGroup>
      </Col>
      </Row>
      <Row>
      <Col md={6}>
      <FormGroup>
        <Label for="first">Latitude</Label>
        <Input type="text" name="latitude" id="first" onChange={onChange} value={vehicleform.latitude} />
      </FormGroup>
      </Col>
      <Col>
      <FormGroup>
        <Label for="first">Longitude</Label>
        <Input type="text" name="longitude" id="first" onChange={onChange} value={vehicleform.longitude} />
      </FormGroup>
      </Col>
      </Row>
      <Row>
      <Col md={6}>
      <FormGroup>
        <Label for="first">Zip Code</Label>
        <Input type="text" name="zipcode" id="first" onChange={onChange} value={vehicleform.zipcode} />
      </FormGroup>
      </Col>
      <Col>
      <FormGroup>
        <Label for="first">Image</Label>
        <Input type="file" name="vehicalImage" id="first" onChange={onChange} defaultValue={vehicleform.vehicalImage} />
      </FormGroup>
      </Col>
      </Row>
      <Button>Submit</Button>
    </Form>
    )
  }else if(props.formtype === 'vehicleCategory'){
    return(
      <Form onSubmit={Object.entries(props.item).length !== 0 ? submitFormEdit : submitFormAdd}>
        <FormGroup>
          <Label for="first">Name</Label>
          <Input type="text" name="name" id="first" onChange={onChange} value={vehicleCatform.name} />
        </FormGroup>
        <FormGroup>
          <Label for="first">Description</Label>
          <Input type="text" name="description" id="first" onChange={onChange} value={vehicleCatform.description} />
        </FormGroup>
        <Button>Submit</Button>
      </Form>
      )
    }else if(props.formtype === 'shopcategory'){
    return(
      <Form onSubmit={Object.entries(props.item).length !== 0 ? submitFormEdit : submitFormAdd}>
        <FormGroup>
          <Label for="first">Name</Label>
          <Input type="text" name="name" id="first" onChange={onChange} value={shopcategoryform.name} />
        </FormGroup>
        <FormGroup>
          <Label for="first">Description</Label>
          <Input type="text" name="description" id="first" onChange={onChange} value={shopcategoryform.description} />
        </FormGroup>
        <Button>Submit</Button>
      </Form>
    )
  }else if(props.formtype === 'productcategory'){
    return(
      <Form onSubmit={Object.entries(props.item).length !== 0 ? submitFormEdit : submitFormAdd}>
        <FormGroup>
          <Label for="first">Name</Label>
          <Input type="text" name="name" id="first" onChange={onChange} value={productcatform.name} />
        </FormGroup>
        <FormGroup>
          <Label for="first">Description</Label>
          <Input type="text" name="description" id="first" onChange={onChange} value={productcatform.description} />
        </FormGroup>
        <Row>
        <FormGroup>
          <Label for="first">Procat Image</Label><br></br>
          <Input type="file" name="proCatImage" id="file" onChange={onChange} value={productcatform.proCatImage} />
        </FormGroup>
        </Row>
        <Button type="submit">Submit</Button>
      </Form>
    )
  }else if(props.formtype === 'productsubcategory'){
    return(
      <Form onSubmit={Object.entries(props.item).length !== 0 ? submitFormEdit : submitFormAdd}>
        <FormGroup>
          <Label for="first">Name</Label>
          <Input type="text" name="name" id="first" onChange={onChange} value={subcatogeryform.name} />
        </FormGroup>
        <FormGroup>
          <Label for="first">Description</Label>
          <Input type="text" name="description" id="first" onChange={onChange} value={subcatogeryform.description} />
        </FormGroup>
        <FormGroup>
          <Label for="first">Category</Label>
          <Input type="text" name="category" id="first" onChange={onChange} value={subcatogeryform.category} />
        </FormGroup>
        <FormGroup>
          <Label for="first">Sub category Image</Label>
          <Input type="file" name="proSubCatImage" id="file" onChange={onChange} value={subcatogeryform.proSubCatImage} />
        </FormGroup>
        <Button>Submit</Button>
      </Form>
    )
  }
  return(
    <h1>Something went wrong, UnAuthorized Access</h1>
  )
}

export default AddEditForm