import {put} from "redux-saga/effects"
import { editContactUsSuccess, getContactUsSuccess } from "../actionCreators/contactUSActions";
import {getAllUsersSuccess, toggleActivationSuccess} from "../actionCreators/userActions";
import { makereq } from "./utils";

var qs = require('qs')

export function* getContactUSSaga() {
  try {
    const headers= { 
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': `Bearer ${JSON.parse(localStorage.getItem('userData')).token}`
    }
    const res = yield makereq('contactUs/','get',{},headers)
    var userList
    if(res.data){
      userList = res.data[0]
    }
    yield put(getContactUsSuccess({userList}))
  } 
  catch (error) {
    console.log(error)
  }
}

export function* editContactUsSaga({payload}) {
  console.log(payload)
  try {
    var data = qs.stringify({
      '_id': payload.id,
      'address': payload.address,
      'email': payload.email,
      'CountryCode': payload.CountryCode,
      'contactNo': payload.contactNo
    });

    const headers= { 
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': `Bearer ${JSON.parse(localStorage.getItem('userData')).token}`
    }
    const res = yield makereq(`contactUS/${payload._id}`,'put',data,headers)
    const userList = res.data
    console.log(userList)
    yield put(editContactUsSuccess({userList}))
  } 
  catch (error) {
    console.log(error)
  }
}