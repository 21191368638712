import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Button, Col, Container, Form, FormGroup, Input, Row } from 'reactstrap';
import { addstaticPageInitiate } from '../../redux/actionCreators/staticPageActions';
import { stringvalidator, toaster } from '../../redux/sagas/utils';
import AdminLtSide from './adminltsidebar';
import Header from './header';

const AddStaticPage = () => {
  const [pageTitle, setPageTitle] = useState('')
  const [metaKeyword, setmetaKeyword] = useState('')
  const [metaKeywordDescription, setmetaKeywordDescription] = useState('')
  const [pageDescription,setPagedescription] = useState('')
  const [important, setImportant] = useState(false)
  const [id,setID] = useState('')
  const dispatch = useDispatch()
  const history = useHistory()

  console.log("ADD STATIC PAGE NEW DATAT")

  
  
  const handlecancel = () => {
    setID('')
    setPageTitle('')
    setmetaKeyword('')
    setPagedescription('')
    setmetaKeywordDescription('')
    history.push('/static-pages')
  }

  const handleAddSubmit = () => {
    const data = {
      id: id,
      title: pageTitle,
      metaKeyword: metaKeyword,
      description: pageDescription ,
      metaDescription: metaKeywordDescription,
    }
    dispatch(addstaticPageInitiate(data))
    handlecancel()
  }

  const handlevalidate = (e) => {
    e.preventDefault();
    if(stringvalidator(pageTitle) && stringvalidator(metaKeyword) && stringvalidator(metaKeywordDescription) &&  stringvalidator(pageDescription)){
      handleAddSubmit()
      return
    }
    setImportant(true)
    toaster("error","Please fill the * marked field")
  }

  const Important = () => {
    return(
      <span style={{color: 'red'}}> * </span>
    )
  }
  
  return (
    <div class="wrapper">
    <Header />
    <AdminLtSide />
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">Static Page</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="container-fluid">  
          <div>
            <div style={{display: 'flex',justifyContent: 'space-between'}}>
              <h3>Manage Content</h3>
              <Button onClick={handlecancel} color="primary" >Cancel</Button>
            </div>
            <Form>
              <Container>
                <FormGroup>
                  <Row>
                    <Col style={{letterSpacing: '0px'}}>
                    Page Title :
                    {important && <Important />}
                    </Col>
                  </Row>
                  <Input value={pageTitle} onChange={(e) => setPageTitle(e.target.value)} />
                </FormGroup>
                <Row xs="2">
                  <Col style={{letterSpacing: '0px'}}>
                    Meta Keyword : {important && <Important />}
                  </Col>
                  <Col>
                    <textarea
                    value={metaKeyword}
                    style={{width: '100%'}}
                    onChange={(e) => setmetaKeyword(e.target.value)}
                  />
                  </Col>
                </Row>
                <Row xs="2">
                  <Col style={{letterSpacing: '0px'}}>
                    Meta Description : {important && <Important />}
                  </Col>
                  <Col>
                    <textarea
                      style={{width: '100%'}}
                      value={metaKeywordDescription}
                      onChange={(e) => setmetaKeywordDescription(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row xs="2">
                  <Col style={{letterSpacing: '0px'}}>
                    Page Description :
                    {important && <Important />}
                  </Col>
                </Row>
                <Row xs="2">
                  <textarea
                    value={pageDescription}
                    onChange={(e) => setPagedescription(e.target.value)}
                    style={{minHeight: '200px', width: '98%', margin: '10px'}}
                  />
                </Row>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                  <Button color="success" onClick={handlevalidate}>Submit</Button>
                </div>
              </Container>
            </Form>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
}

export default AddStaticPage;