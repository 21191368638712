import React, { useEffect } from "react"
import './index.css'
import { useDispatch, useSelector, batch } from "react-redux";
import SettingsDisplay from '../settings/index'
import { fetchSettingsInitiate } from "../../redux/actionCreators/settingsActions";
import Header from "./header";
import AdminLtSide from "./adminltsidebar";
import ContactusDisplay from "../contactus";
import { getContactUsInitiate } from "../../redux/actionCreators/contactUSActions";

const ContactUsDisp = () => {
  const userInfo = [
    {address: '125bs',
    contactNo: '15258932',
    CountryCode: '1526',
    email: 'dsgsg'
  }
]

  const dispatch = useDispatch();
  useEffect(() => {
    batch(()=>{
      dispatch(getContactUsInitiate());
    })
  },[])
  const {contactUSData} = useSelector(state => state.contactUSData)
  console.log(contactUSData)
  return(
    <div class="wrapper">
    <Header />
    <AdminLtSide />
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
        <div className="row mb-2">
          <div className="col-sm-6">
            <h1 className="m-0">Contact Us</h1>
          </div>
        </div>
        </div>
        </div>
        <div className="content">
          <div className="container-fluid">
            <ContactusDisplay userInfo={contactUSData}/>
          </div>
        </div>
      </div>
    </div>
  )
}
export default ContactUsDisp