import {put} from "redux-saga/effects"
import {getAllUsersSuccess, toggleActivationSuccess} from "../actionCreators/userActions";
import { makereq } from "./utils";

var qs = require('qs')

export function* getalluserSaga() {
  try {
    const headers= { 
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': `Bearer ${JSON.parse(localStorage.getItem('userData')).token}`
    }
    const res = yield makereq('user/','get',{},headers)
    const userList = res.data
    yield put(getAllUsersSuccess({userList}))
  } 
  catch (error) {
    console.log(error)
  }
}

export function* toggleActivation({payload}) {
  try {
    var data = qs.stringify({
      '_id': payload.id,
      'status': payload.status 
    });

    const headers= { 
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': `Bearer ${JSON.parse(localStorage.getItem('userData')).token}`
    }
    const res = yield makereq(`user/${payload.id}`,'put',data,headers)
    const userList = res.data
    console.log(userList)
    yield put(toggleActivationSuccess({userList}))
  } 
  catch (error) {
    console.log(error)
  }
}