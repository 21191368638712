import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Table, Button } from "reactstrap";
import { deleteVehicleIntiate } from "../../redux/actionCreators/vehicleActions";
import ModalForm from "../Modal/index";
import _ from "lodash";
import './index.css'
import ReactPaginate from 'react-paginate'

function VehicleTable({tabledata,show}) {
  const dispatch = useDispatch()
  const [modaldata, setmodaldata] = useState({})
  const [pageNumber,setPageNumber] = useState(0)
  const [showmodal, setshowmodal] = useState(false)
  const vehicleheaders = ["Name","Description","Address","Image","Actions"] //user, category, "Lat,Long"
  const usersPerPage = 8
  const pageVisited = pageNumber*usersPerPage
  const diplayusers = tabledata.slice(pageVisited, pageVisited+usersPerPage)
  console.log(tabledata)
  const VisualRepresentation = () => {
    return(
      <Table responsive>
      <thead>
        <tr>
          {vehicleheaders.map((item,key) => 
              <td key={key}>
                {item}
              </td>
          )}
          <td>
            <Button
              onClick={() => setshowmodal(true)}
              color="success"
              style={{float: "left", marginRight:"10px", marginTop:"10px"}}
            >
              Add
            </Button>
          </td>
        </tr>
      </thead>
      <tbody>
        {diplayusers.map((item,key) => (
          <tr key={key}>
            <td>
              {item.vehicalName}
            </td>
            {/* <td>
              {item.user}
            </td> */}
            <td>
              {item.description}
            </td>
            {/* <td>
              {item.category}
            </td> */}
            <td>
              {item.address}
            </td>
            <td>
              <img width="100px" height="60px" src={item.image} alt="Error" />
            </td>
            <td>
              <Button color="primary" onClick={(event) => clickHandler(event,item._id,'vehicle')} style={{cursor: 'pointer'}}>
                Edit
              </Button>
            </td>
            <td>
              <Button color="danger" onClick={(event) => handleVehicalDelete(event,item._id)}>Delete</Button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
    )
  }
  
  const deactivateModal = () => {
    setmodaldata({})
    setshowmodal(false);
  };
  
  const handleVehicalDelete = (event,id) => {
    event.stopPropagation()
    dispatch(deleteVehicleIntiate(id))
  }

  const clickHandler = (event,id) => {
    event.stopPropagation();
    const formData = _.find(tabledata, (tabledata) => tabledata._id === id);
    setmodaldata(formData);
    setshowmodal(!showmodal)
  }
  const changePage = ({selected}) => {
    setPageNumber(selected)
  }
  const pageCount = Math.ceil(tabledata.length / usersPerPage)
  return(
    <React.Fragment>
        {showmodal && <ModalForm show={show} deactivate={deactivateModal} item={modaldata}/>}
        <VisualRepresentation />
        <ReactPaginate
          previousLabel={"Prev"}
          nextLabel={"Next"}
          pageCount={pageCount}
          onPageChange={changePage}
          containerClassName={"paginationBttns"}
          previousLinkClassName={"previousBttn"}
          nextLinkClassName={"nextBttn"}
          disabledClassName={"paginationDisabled"}
          activeClassName={"paginationActive"}
        />
      </React.Fragment>
  )
}

export default VehicleTable;
