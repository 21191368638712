// fetchproductsubcategory
import {put} from "redux-saga/effects"
import { addSubcategorySuccess, deleteSubcategorySuccess, editSubcategorySuccess, fetchallSubCategorySuccess, fetchProductSubCategorySuccess } from "../actionCreators/productSubcategoryActions";
import { makereq, toaster } from "./utils";

export function* addSubcategorySaga({payload}) {
  try {
    const formData = new FormData();
    formData.append('name', payload.name);
    formData.append('description', payload.description);
    formData.append('category', payload.category);
    formData.append("proSubCatImage", payload.image, payload.image.name);

    const headers= { 
      'Content-Type': 'multipart/form-data', 
      'Authorization': `Bearer ${JSON.parse(localStorage.getItem('userData')).token}`
    }
    const res = yield makereq(`subCategory`,'post',formData,headers)
    console.log(res)
    const {data} = res
    yield put(addSubcategorySuccess({data}))
    toaster('success','Subcategory added successfully')
  }catch (error) {
    error.message ? toaster('error', error.message) : toaster('error', 'Error Occured')
    console.log(error.message)
  }
}

export function* editSubcategorySaga({payload}) {
  try {
    const formData = new FormData();
    formData.append('catname', payload.name);
    formData.append('description', payload.description);
    formData.append('category', payload.category);
    formData.append("proSubCatImage", payload.image, payload.image.name);

    const headers= { 
      'Content-Type': 'multipart/form-data', 
      'Authorization': `Bearer ${JSON.parse(localStorage.getItem('userData')).token}`
    }

    const res = yield makereq(`subCategory/${payload.id}`,'put',formData,headers)
    console.log(res)
    const {data} = res
    console.log(res)
    yield put(editSubcategorySuccess({data}))
    toaster('success','Sub Category updated successfully')
  }catch (error) {
    error.message ? toaster('error', error.message) : toaster('error', 'Error Occured')
    console.log(error)
  }
}


export function* fetchproductsubcategorySaga({payload}) {
  try {
    const headers = { 
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': `Bearer ${JSON.parse(localStorage.getItem('userData')).token}`
    }
    const res = yield makereq(`subCategory/${payload}`,'get',{},headers)
    console.log(res)
    const {data} = res
    yield put(fetchProductSubCategorySuccess({data}))
  }catch (error) {
    error.message ? toaster('error', error.message) : toaster('error', 'Error Occured')
    console.log(error)
  }
}

export function* fetchallSubCategorySaga({payload}) {
  try {
    const headers = { 
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': `Bearer ${JSON.parse(localStorage.getItem('userData')).token}`
    }
    const res = yield makereq(`subCategory/`,'get',{},headers)
    console.log(res)
    const {data} = res
    yield put(fetchallSubCategorySuccess({data}))
  }catch (error) {
    error.message ? toaster('error', error.message) : toaster('error', 'Error Occured')
    console.log(error)
  }
}


export function* deleteSubCatSaga({payload}) {
  try {
    const headers = { 
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': `Bearer ${JSON.parse(localStorage.getItem('userData')).token}`
    }
    yield makereq(`subCategory/${payload}`,'delete',{},headers)
    yield put(deleteSubcategorySuccess(payload))
    toaster('success', "Sub category deleted successfully")
  }catch (error) {
    console.log(error)
    error.message ? toaster('error', error.message) : toaster('error', 'Error Occured')
  }
}

