import './index.css'
import _ from "lodash";
import ModalForm from "../Modal/index";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import ReactPaginate from 'react-paginate'
import { Table, Button } from "reactstrap";
import { deleteShopCategoryInitiate } from "../../redux/actionCreators/shopcategoryActions";

function ShopCategoryTable({tabledata,show,search,searchEdit}) {
  const dispatch = useDispatch()
  const [modaldata, setmodaldata] = useState({})
  const [pageNumber,setPageNumber] = useState(0)
  const [showmodal, setshowmodal] = useState(false)
  const usersPerPage = 8 
  const pageVisited = pageNumber*usersPerPage
  const shopcategoryheaders = ["Name", "Description","Actions"]
  const diplayusers = tabledata ? tabledata.slice(pageVisited, pageVisited+usersPerPage) : []

  const VisualRepresentation = () => {
    return(
      <Table responsive>
      <thead>
        <tr>
          {shopcategoryheaders.map((item,key) => 
              <td>
                {item}
              </td>
          )}
          <td>
            <Button
              onClick={() => setshowmodal(true)}
              color="success"
              style={{float: "left", marginRight:"10px", marginTop:"10px"}}
            >
              Add
            </Button>
          </td>
        </tr>
      </thead>
      <tbody>
        {diplayusers.map((item,key) => (
          <tr>
            <td>
              {item.name}
            </td>
            <td>
              {item.description}
            </td>
            <td>
              <Button  color="primary" onClick={(event) => clickHandler(event,item._id,'vehicle')} style={{cursor: 'pointer'}}>Edit</Button>
            </td>
            <td>
              <Button color={item.isDeleted ? "primary" : "danger"} onClick={(event) => handleShopCategoryDelete(event,item._id)}>{item.isDeleted ? "Deleted" : "Delete"}</Button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
    )
  }
  
  const deactivateModal = () => {
    setmodaldata({})
    setshowmodal(false);
  };

  const handleShopCategoryDelete = (event,id) => {
    // event.stopPropagation()
    // dispatch(deleteShopCategoryInitiate(id))
    event.stopPropagation()
    dispatch(deleteShopCategoryInitiate(id))
  }

  const clickHandler = (event,id) => {
    event.stopPropagation();
    const formData = _.find(tabledata, (tabledata) => tabledata._id === id);
    setmodaldata(formData);
    setshowmodal(!showmodal)
  }
  const changePage = ({selected}) => {
    setPageNumber(selected)
  }
  const pageCount = Math.ceil(tabledata.length / usersPerPage)
  return(
    <React.Fragment>
        {showmodal &&
          <ModalForm
            search={search}
            searchEdit={(newitem) => searchEdit(newitem)}
            show={show}
            deactivate={deactivateModal}
            item={modaldata}
          />
        }
        <VisualRepresentation />
        <ReactPaginate
          previousLabel={"Prev"}
          nextLabel={"Next"}
          pageCount={pageCount}
          onPageChange={changePage}
          containerClassName={"paginationBttns"}
          previousLinkClassName={"previousBttn"}
          nextLinkClassName={"nextBttn"}
          disabledClassName={"paginationDisabled"}
          activeClassName={"paginationActive"}
        />
      </React.Fragment>
  )
}

export default ShopCategoryTable;
