import './index.css'
import Header from "./header";
import Search from "../Search";
import AdminLtSide from "./adminltsidebar";
import OrderTable from '../Tablee/orderTable';
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector, batch } from "react-redux";
import { fetchOrdersInitiate } from '../../redux/actionCreators/orderActions';
import { toaster } from '../../redux/sagas/utils';
import { Button } from 'reactstrap';



const OrderDisplay = () => {
  const [searchBy, setSearchBy] = useState('');
  const [search, setsearch] = useState('');
  const [searchRes,setSearchRes] = useState('')

  const orderlist = useSelector(state => state.orderState.orders)
  
  const dispatch = useDispatch();
  useEffect(() => {
    batch(()=>{
      dispatch(fetchOrdersInitiate());
    })
  },[])
  
  const handleSubmit = () => {
    if(searchBy.trim() && search.trim()){
      if(searchBy === 'shopId'){
        const newArr = orderlist.filter((itm)=> itm.shop_id === search)
        if(newArr.length){
          setSearchRes(newArr)
          return
        }
      }else if(searchBy === 'orderId'){
        const newArr = orderlist.filter((itm)=> itm._id === search)
        if(newArr.length){
          setSearchRes(newArr)
          return
        }
      }else if(searchBy === 'userId'){
        const newArr = orderlist.filter((itm)=> itm.user === search)
        if(newArr.length){
          setSearchRes(newArr)
          return
        }
      }
      toaster("error","could not find anything for your search")
      return
    }
  }
  const handleback = () => {
    setSearchRes('')
    setsearch('')
    setSearchBy('')
  }

  const dropdownArray = [
    {
      name: 'Order Id',
      value: 'orderId'
    },
    {
      name: 'Shop Id',
      value: 'shopId'
    },
    {
      name: 'User Id',
      value: 'userId'
    }
  ]

  const TableView = () => {
    return(
      <div class="hold-transition skin-blue sidebar-collapse">
        {searchRes &&
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <p>Search Result</p>
          <Button color="primary" onClick={handleback}>Back</Button>
        </div> 
        }
        {
          searchRes
          ? <OrderTable show={"vehicle"} tabledata={searchRes}/> 
          : <OrderTable show={"vehicle"} tabledata={orderlist && orderlist.length ? orderlist : []}/>
        }
      </div>
    )
  }

  return(
    <div class="wrapper">
    <Header />
    <AdminLtSide />
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
        <div className="row mb-2">
          <div className="col-sm-6">
            <h1 className="m-0">Orders</h1>
          </div>
          <div className="col-sm-3">
            <Search
              inputstate={search}
              dropdownstate={searchBy}
              handleSubmit={handleSubmit}
              dropdownarray={dropdownArray}
              inputchange={(value) => setsearch(value)}
              dropdownchange={(value) => setSearchBy(value)}
            />
          </div>
        </div>
        </div>
        </div>
        <div className="content">
          <div className="container-fluid">  
          <TableView />
          </div>
        </div>
      </div>
    </div>
  )
}
export default OrderDisplay