import {productSubcategoryActions } from "../actionTypes";

// 'FETCH_ALLSUBCATEGORY_INITIATE',
//   FETCH_ALLSUBCATEGORY_SUCCESS


// ---------------------------Product Cateegory actions------------------------------------
export const fetchProductSubCategoryInitiate = (data) => {
  return {
    type: productSubcategoryActions.FETCH_PRODUCTSUBCATEGORY_INITIATE,
    payload: data
  }
}
export const fetchProductSubCategorySuccess = (data) => {
  return {
    type: productSubcategoryActions.FETCH_PRODUCTSUBCATEGORY_SUCCESS,
    payload: data,
  };
};
export const fetchallSubCategoryInitiate = (data) => {
  return {
    type: productSubcategoryActions.FETCH_ALLSUBCATEGORY_INITIATE,
    payload: data
  }
}
export const fetchallSubCategorySuccess = (data) => {
  return {
    type: productSubcategoryActions.FETCH_ALLSUBCATEGORY_SUCCESS,
    payload: data,
  };
};
export const deleteSubcategoryInitiate = (data) => {
  return {
    type: productSubcategoryActions.DELETE_PRODUCTSUBCATEGORY_INITIATE,
    payload: data
  }
}
export const deleteSubcategorySuccess = (data) => {
  console.log('action')
  return {
    type: productSubcategoryActions.DELETE_PRODUCTSUBCATEGORY_SUCCESS,
    payload: data,
  };
};
export const editSubcategoryInitiate = (data) => {
  return {
    type: productSubcategoryActions.EDIT_PRODUCTSUBCATEGORY_INITIATE,
    payload: data
  }
}
export const editSubcategorySuccess = (data) => {
  return {
    type: productSubcategoryActions.EDIT_PRODUCTSUBCATEGORY_SUCCESS,
    payload: data,
  };
};
export const addSubcategoryInitiate = (data) => {
  return {
    type: productSubcategoryActions.ADD_PRODUCTSUBCATEGORY_INITIATE,
    payload: data
  }
}
export const addSubcategorySuccess = (data) => {
  return {
    type: productSubcategoryActions.ADD_PRODUCTSUBCATEGORY_SUCCESS,
    payload: data,
  };
};