

const PrivacyPolicyDisplay = ({privacyPolicy}) => {

  if(privacyPolicy){
    console.log(privacyPolicy[0].title,"then the prifacct")
    return(
      <div class="wrapper">
        <div className="content">
          <div className="container-fluid">  
            <h1>{privacyPolicy[0].title}</h1>
          </div>
          <div className="container-fluid">  
            <div>
              {privacyPolicy[0].description}
            </div>
          </div>
        </div>
      </div>
    )
  }
  return(
    <div class="wrapper">
      <div className="content">
        <div className="container-fluid">  
          <h1>Privacy  policy</h1>
        </div>
        <div className="container-fluid">  
          <div>
            Nothing to show here
          </div>
        </div>
      </div>
    </div>
  )

}

export default PrivacyPolicyDisplay