import Home from './components/home/index';
import Login from './components/login/login';
import ShopDisplay from './components/home/shop';
import Register from './components/register/index'
import OrderDisplay from './components/home/order';
import TermsOfUs from './components/home/termsOfuse';
import SettingsDisp from './components/home/settings';
import VehicleDisplay from './components/home/vehicle';
import StaticPages from './components/home/staticpage';
import ProductDisplay from './components/home/product';
import ProtectedRoute from './components/ProtectedRoute';
import Notfound from './components/PageNotFound/notfound';
import AddStaticPage from './components/home/addStaticPage';
import PrivacyPolicy from './components/home/privacypolicy';
import SubcategoryDisplay from './components/home/subcategory';
import TransactionDisplay from './components/home/transaction';
import ProductCategoryDisplay from './components/home/category';
import ShopcategoryDisplay from './components/home/shopcategory';
import VehicleCategoryDisplay from './components/home/vehicleCategory';
import { BrowserRouter, Route, Switch, Redirect} from "react-router-dom";
import UserDisplay from './components/home/user';
import ContactUsDisp from './components/home/contactUs';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <Switch>
        <Route path="/sign-in" exact component={Login} />
        <Route path="/sign-up" exact component={Register} />
        <ProtectedRoute path="/shop" exact component={ShopDisplay} />
        <ProtectedRoute path="/users" exact component={UserDisplay} />
        <ProtectedRoute path="/vehicleCategory" exact component={VehicleCategoryDisplay} />
        <ProtectedRoute path="/vehicle" exact component={VehicleDisplay} />
        <ProtectedRoute path="/product" exact component={ProductDisplay} />
        <ProtectedRoute path="/productcategory" exact component={ProductCategoryDisplay} />
        <ProtectedRoute path="/shopcategory" exact component={ShopcategoryDisplay} />
        <ProtectedRoute path="/productsubcategory" exact component={SubcategoryDisplay} />
        <ProtectedRoute path="/admin-settings" exact component={SettingsDisp} />
        <ProtectedRoute path="/static-pages" exact component={StaticPages} />
        <ProtectedRoute path="/static-pages/privacyPolicy" component={PrivacyPolicy} />
        <ProtectedRoute path="/contactUS" component={ContactUsDisp} />
        <ProtectedRoute path="/static-pages/termsOfUse" component={TermsOfUs} />
        <ProtectedRoute path="/transactions" exact component={TransactionDisplay} />
        <ProtectedRoute path="/orders" exact component={OrderDisplay} />
        <ProtectedRoute path="/add-static-content" exact component={AddStaticPage} />
        <ProtectedRoute path="/home" exact component={Home} />
        <Route
          exact
          path="/"
          render={() => {
            return (
              localStorage.getItem('userData')
              ? <Redirect to="/home" />
              : <Redirect to="/sign-in"/> 
            )
          }}
        />
        <Route path='*' exact={true} component={Notfound} />
      </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
