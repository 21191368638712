import React, { useEffect } from "react"
import './index.css'
import { useDispatch, useSelector, batch } from "react-redux";
import Header from "./header";
import AdminLtSide from "./adminltsidebar";
import PrivacyPolicyDisplay from "../privacyPolicy";
import { fetchPrivacyPolicyInitiate } from "../../redux/actionCreators/vehicleActions";

const PrivacyPolicy = () => {
  const privacyPolicy = useSelector(state => state.vehicleState.privacyPolicy)
  console.log(useSelector(state => state.vehicleState))
  
  const dispatch = useDispatch();
  useEffect(() => {
    batch(()=>{
      dispatch(fetchPrivacyPolicyInitiate());
    })
  },[])
  return(
    <div class="wrapper">
      <Header />
      <AdminLtSide />
        <div className="content-wrapper">
          <div className="content">
            <div className="container-fluid">
              <PrivacyPolicyDisplay privacyPolicy={privacyPolicy}/>
            </div>
          </div>
        </div>
    </div>
  )
}
export default PrivacyPolicy