import './index.css'
import Header from "./header";
import Search from "../Search";
import AdminLtSide from "./adminltsidebar";
import VehicleTable from "../Tablee/vehicle";
import { toaster } from '../../redux/sagas/utils';
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, batch } from "react-redux";
import { fetchVehicleInitiate } from "../../redux/actionCreators/vehicleActions";

const VehicleDisplay = () => {
  const dispatch = useDispatch();
  const [search, setsearch] = useState('');
  const [searchBy, setSearchBy] = useState('name');
  const [searchRes,setsearchRes] = useState('');
  const {vehiclelist} = useSelector(state=> state.vehicleState)
  useEffect(() => {
    batch(()=>{
      dispatch(fetchVehicleInitiate());
    })
  },[])

  const dropdownArray = [
    {
      name: 'Name',
      value: 'name'
    },
    // {
    //   name: 'User ID',
    //   value: 'userId'
    // },
    // {
    //   name: 'Cat Id',
    //   value: 'catId'
    // },
    // {
    //   name: 'Lat,Long',
    //   value: 'lat,long'
    // } later

  ]
  
  const handleSubmit = () => {
    if(searchBy.trim() && search.trim()){
      if(searchBy === 'name'){
        const newArr = vehiclelist.filter((itm)=> itm.vehicalName === search)
        if(newArr.length){
          setsearchRes(newArr)
          setsearch('')
          return
        }
      }else if(searchBy === 'catId'){
        console.log(vehiclelist)
        const newArr = vehiclelist.filter((itm)=> itm.category === search)
        if(newArr.length){
          setsearchRes(newArr)
          setsearch('')
          return
        }
      }
      toaster("error","could not find anything for your search")
      return
    }
  }

  const handleback = () => {
    setsearchRes('')
    setsearch('')
  }
  const TableView = () => {
    return(
      <div class="hold-transition skin-blue sidebar-collapse">
        {searchRes &&
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <p>Search Result</p>
          <button onClick={handleback}>Back</button>
        </div> 
        }
        {
          searchRes
          ? <VehicleTable show={"vehicle"} tabledata={searchRes}/> 
          : <VehicleTable show={"vehicle"} tabledata={vehiclelist && vehiclelist.length ? vehiclelist : []}/>
        }
      </div>
    )
  }
  
  return(
    <div class="wrapper">
    <Header />
    <AdminLtSide />
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
        <div className="row mb-2">
          <div className="col-sm-6">
            <h1 className="m-0">Vehicles</h1>
          </div>
          <div className="col-sm-3">
            <Search
              dropdown={false}
              inputstate={search}
              dropdownstate={searchBy}
              handleSubmit={handleSubmit}
              dropdownarray={dropdownArray}
              inputchange={(value) => setsearch(value)}
              dropdownchange={(value) => setSearchBy(value)}
            />
          </div>
        </div>
        </div>
        </div>
        <div className="content">
          <div className="container-fluid">  
            <TableView />
          </div>
        </div>
      </div>
    </div>
  )
}
export default VehicleDisplay