import './index.css'
import Header from "./header";
import Search from "../Search";
import AdminLtSide from "./adminltsidebar";
import { toaster } from "../../redux/sagas/utils";
import ProductTable from "../Tablee/producttable";
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector, batch } from "react-redux";
import { fetchProductInitiate } from "../../redux/actionCreators/vehicleActions";
import { Button } from 'reactstrap';

const ProductDisplay = () => {
  const [search, setsearch] = useState('');
  const [searchBy, setSearchBy] = useState('');
  const [searchRes,setsearchRes] = useState('');
  const productlist = useSelector(state => state.vehicleState.productlist)
  const dropdownArray = [
    {
      name: 'Name',
      value: 'name'
    },
    {
      name: 'ID',
      value: 'id'
    },
    // {
    //   name: 'Category',
    //   value: 'category'
    // },
    // {
    //   name: 'Lat,Long',
    //   value: 'lat,long'
    // }
  ]

  const dispatch = useDispatch();
  useEffect(() => {
    batch(()=>{
      dispatch(fetchProductInitiate());
    })
  },[])

  const handleSubmit = () => {
    if(searchBy.trim() && search.trim()){
      if(searchBy === 'name'){
        const newArr = productlist.filter((itm)=> itm.name === search)
        console.log(newArr)
        if(newArr.length){
          setsearchRes(newArr)
          return
        }
      }else if(searchBy === 'id'){
        const newArr = productlist.filter((itm)=> itm._id === search)
        if(newArr.length){
          setsearchRes(newArr)
          return
        }
      }
      toaster("error","could not find anything for your search")
      return
    }
    //   if(searchBy === 'lat,long'){
    //     //split on ',' and make an object
    //   }
    // }
    //dispatch
  }

  const handleback = () => {
    setsearchRes('')
    setsearch('')
    setSearchBy('')
  }
  const TableView = () => {
    return(
      <div class="hold-transition skin-blue sidebar-collapse">
        {searchRes &&
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <p>Search Result</p>
          <Button color="primary" style={{padding: '0px 28px 0px 28px'}} onClick={handleback}>Go Back</Button>
        </div> 
        }
        {
          searchRes
          ? <ProductTable show={"vehicle"} tabledata={searchRes}/> 
          : <ProductTable show={"product"} tabledata={productlist && productlist.length? productlist : []}/>
        }
      </div>
    )
  }

  return(
    <div class="wrapper">
    <Header />
    <AdminLtSide />
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
        <div className="row mb-2">
          <div className="col-sm-6">
            <h1 className="m-0">Product</h1>
          </div>
          <div className="col-sm-3">
            <Search
              inputstate={search}
              dropdownstate={searchBy}
              handleSubmit={handleSubmit}
              dropdownarray={dropdownArray}
              inputchange={(value) => setsearch(value)}
              dropdownchange={(value) => setSearchBy(value)}
            />
          </div>
        </div>
        </div>
        </div>
        <div className="content">
          <div className="container-fluid">
            <TableView />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductDisplay