import React, { useEffect } from "react"
import './index.css'
import { useDispatch, useSelector, batch } from "react-redux";
import SettingsDisplay from '../settings/index'
import { fetchSettingsInitiate } from "../../redux/actionCreators/settingsActions";
import Header from "./header";
import AdminLtSide from "./adminltsidebar";
import PrivacyPolicyDisplay from "../privacyPolicy";
import { fetchPrivacyPolicyInitiate, fetchTermsOfUseInitiate } from "../../redux/actionCreators/vehicleActions";
import TermsOfUse from "../termsOfuse";

const TermsOfUs = () => {
  const terms = useSelector(state => state.vehicleState.termsOfUse)
  const dispatch = useDispatch();
  console.log(useSelector(state => state.vehicleState))
  useEffect(() => {
    batch(()=>{
      dispatch(fetchTermsOfUseInitiate());
    })
  },[])
  return(
    <div class="wrapper">
    <Header />
    <AdminLtSide />
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
        </div>
        </div>
        <div className="content">
          <div className="container-fluid">
            <TermsOfUse terms={terms}/>
          </div>
        </div>
      </div>
    </div>
  )
}
export default TermsOfUs