import './index.css'
import Header from "./header";
import Search from "../Search";
import { Button } from 'reactstrap';
import AdminLtSide from "./adminltsidebar";
import {  toaster } from '../../redux/sagas/utils';
import React, { useEffect, useState } from "react";
import VehicleTable from "../Tablee/vehicleCategoryTable";
import { useDispatch, useSelector, batch } from "react-redux";
import {
  fetchVehicleCategoryInitiate
} from '../../redux/actionCreators/vehicalCategoryActions';

const VehicleCategoryDisplay = () => {
  const dispatch = useDispatch();
  const [search, setsearch] = useState('');
  const [searchBy, setSearchBy] = useState('name');
  const [searchRes,setsearchRes] = useState('');
  const {vehicleCategorylist} = useSelector(state => state.vehicleCategoryState)
  
  useEffect(() => {
    batch(()=>{
      dispatch(fetchVehicleCategoryInitiate());
    })
  },[])
  
  const dropdownArray = [
    {
      name: 'Name',
      value: 'name'
    }
  ]
  
  const handleSubmit = () => {
    if(search.trim()){
      const newArr = vehicleCategorylist.filter((itm)=> itm.name === search)
      if(newArr.length){
        setsearchRes(newArr)
        return
      }
    toaster("error","could not find anything for your search")
    return
    }
  }

  const handleback = () => {
    setsearchRes('')
    setsearch('')
  }


  
  const TableView = () => {
    return(
      <div class="hold-transition skin-blue sidebar-collapse">
        {searchRes &&
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <p>Search Result</p>
          <Button color="primary" onClick={handleback}>Back</Button>
        </div> 
        }
        {
          searchRes
          ? <VehicleTable
              show={"vehicleCategory"}
              tabledata={searchRes}
            /> 
          : <VehicleTable
              show={"vehicleCategory"}
              tabledata={
                vehicleCategorylist && vehicleCategorylist.length 
                  ? vehicleCategorylist 
                  : []
              }
            />
        }
      </div>
    )
  }
  
  return(
    <div class="wrapper">
    <Header />
    <AdminLtSide />
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">Vehicles - Category</h1>
            </div>
            <div className="col-sm-3">
              <Search
                dropdown={false}
                inputstate={search}
                dropdownstate={searchBy}
                handleSubmit={handleSubmit}
                dropdownarray={dropdownArray}
                inputchange={(value) => setsearch(value)}
                dropdownchange={(value) => setSearchBy(value)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="container-fluid">
          <TableView />
        </div>
      </div>
    </div>
  </div>
  )
}
export default VehicleCategoryDisplay