import { productSubcategoryActions } from "../actionTypes";

const intitalState = {
  allSubCategorylist: []
};

export const productSubCategoryReducer = (state = intitalState, action) => {
  const {payload,type} = action
  switch (type) {
    case productSubcategoryActions.FETCH_PRODUCTSUBCATEGORY_INITIATE: {
      return {
        ...state,
      };
    }
    case productSubcategoryActions.FETCH_PRODUCTSUBCATEGORY_SUCCESS:{
      return {
        ...state,
        allSubCategorylist: payload.data,
      };
    }
    case productSubcategoryActions.FETCH_ALLSUBCATEGORY_INITIATE: {
      return {
        ...state,
      };
    }
    case productSubcategoryActions.FETCH_ALLSUBCATEGORY_SUCCESS:{
      return {
        ...state,
        allSubCategorylist: payload.data,
      };
    }
    case productSubcategoryActions.DELETE_PRODUCTSUBCATEGORY_INITIATE: {
      return {
        ...state,
      };
    }
    case productSubcategoryActions.DELETE_PRODUCTSUBCATEGORY_SUCCESS:{
      console.log('reducer')
      const res = state.allSubCategorylist.filter((itm) => itm._id !== payload)
      return {
        ...state, allSubCategorylist: res
      }
    }
    case productSubcategoryActions.ADD_PRODUCTSUBCATEGORY_INITIATE: {
      return {
        ...state,
      };
    }
    case productSubcategoryActions.ADD_PRODUCTSUBCATEGORY_SUCCESS:{
      console.log(payload)
      var newlist=[]
      const newObj = {
        _id: payload.data.id,
        name: payload.data.name,
        category: payload.data.category,
        description: payload.data.description,
        image11: payload.data.image11,
        categorys: [
          {
            name: payload.data.categorys
          }
        ]
      }
      if(state.allSubCategorylist.length){
        newlist = [...state.allSubCategorylist,newObj]
      }
      console.log(newlist)
      return {
        ...state,
        allSubCategorylist: newlist
      }
    }
    case productSubcategoryActions.EDIT_PRODUCTSUBCATEGORY_INITIATE: {
      return {
        ...state,
      };
    }
    case productSubcategoryActions.EDIT_PRODUCTSUBCATEGORY_SUCCESS:{
      const newObj = {
        _id: payload.data.id,
        name: payload.data.name,
        category: payload.data.category,
        description: payload.data.description,
        image11: payload.data.image,
        categorys: [
          {
            name: payload.data.categorys
          }
        ]
      }
      let index = state.allSubCategorylist.findIndex( element => {
        if (element._id === newObj._id) {
          return true;
        }
        return false;
      });
      const newArray = [...state.allSubCategorylist];
      newArray.splice(index, 1, newObj);
      return {
        ...state,
        allSubCategorylist: newArray
      }
    }

    default:
      return {
        ...state,
      };
  }
};
