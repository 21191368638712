const TermsOfUse = ({terms}) => {
  console.log(terms,"these are the terms")

  if(terms){
    console.log(terms[0].title,"then the prifacct")
    return(
      <div class="wrapper">
        <div className="content">
          <div className="container-fluid">  
            <h1>{terms[0].title}</h1>
          </div>
          <div className="container-fluid">  
            <div>
              {terms[0].description}
            </div>
          </div>
        </div>
      </div>
    )
  }
  return(
    <div class="wrapper">
      <div className="content">
        <div className="container-fluid">  
          <h1>Terms of use</h1>
        </div>
        <div className="container-fluid">  
          <div>
            Nothing to show here
          </div>
        </div>
      </div>
    </div>
  )

}

export default TermsOfUse