import {productCategoryActions } from "../actionTypes";

// ---------------------------Product Cateegory actions------------------------------------
export const fetchProductCategoryInitiate = (data) => {
  return {
    type: productCategoryActions.FETCH_PRODUCTCATEGORY_INITIATE,
    payload: data
  }
}
export const fetchProductCategorySuccess = (data) => {
  return {
    type: productCategoryActions.FETCH_PRODUCTCATEGORY_SUCCESS,
    payload: data,
  };
};
export const deleteproductcategoryinitiate = (data) => {
  return {
    type: productCategoryActions.DELETE_PRODUCTCATEGORY_INITIATE,
    payload: data
  }
}
export const deleteproductcategorysuccess = (data) => {
  return {
    type: productCategoryActions.DELETE_PRODUCTCATEGORY_SUCCESS,
    payload: data,
  };
};
export const addproductcategoryinitiate = (data) => {
  return {
    type: productCategoryActions.ADD_PRODUCTCATEGORY_INITIATE,
    payload: data
  }
}
export const addproductcategorysuccess = (data) => {

  return {
    type: productCategoryActions.ADD_PRODUCTCATEGORY_SUCCESS,
    payload: data,
  };
};
export const editproductcategoryinitiate = (data) => {
  return {
    type: productCategoryActions.EDIT_PRODUCTCATEGORY_INITIATE,
    payload: data
  }
}
export const editproductcategorysuccess = (data) => {
  return {
    type: productCategoryActions.EDIT_PRODUCTCATEGORY_SUCCESS,
    payload: data,
  };
};