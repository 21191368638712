import $ from 'jquery';
import { Link } from "react-router-dom"

const AdminLtSide = () => {
  // $(document).ready(function () {
  //   $('.nav-item').on("click",function () {
  //     $('.sidebar-mini').removeClass('sidebar-open');
  //     $('.sidebar-mini').addClass('sidebar-closed sidebar-collapse');
  //   });
  // });

  $(function () {
    $('.nav-item').on("click",function () {
      $('.sidebar-mini').removeClass('sidebar-open');
      $('.sidebar-mini').addClass('sidebar-closed sidebar-collapse');
    });
  });
  
  return(
      <aside className="main-sidebar sidebar-dark-primary elevation-4">
        <Link to='/' className="brand-link">
          <span className="brand-text font-weight-light">Ecolive</span>
        </Link>
        <div className="sidebar">
          <div className="user-panel mt-3 pb-3 mb-3 d-flex">
            <div className="image">
            <i class="img-circle fas fa-user-shield fa-2x" style={{color: '#fff'}}></i>
            </div>
            <div className="info" style={{color: 'white'}}>
              Admin
            </div>
          </div>
          <nav className="mt-2">
            <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
              <li className="nav-item">
                <Link to="/home" className="nav-link">
                  <i className="nav-icon fas fa-tachometer-alt" />
                  <p>
                    Dashboard
                  </p>
                </Link>
              </li>

              <li className="nav-item">
                <Link to="/users" className="nav-link">
                  <i className="nav-icon fas fa-users"/>
                  <p>
                    Users
                  </p>
                </Link>
              </li>


              <li className="nav-item">
                <Link to="/vehicle" className="nav-link">
                  <i className="nav-icon fas fa-truck-monster" />
                  <p>
                    Vehicle
                  </p>
                </Link>
              </li>
              {/* <i class="fa-solid fa-bag-shopping"></i> */}
              <li className="nav-item">
                <Link to="/vehicleCategory" className="nav-link">
                  <i className="nav-icon fas fa-truck-monster" />
                  <p>
                    Vehicle Category
                  </p>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/shop" className="nav-link">
                  <i class="nav-icon fas fa-store" />
                  <p>
                    Shop
                  </p>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/orders" className="nav-link">
                <i class="nav-icon fas fa-shopping-bag"/>
                  <p>
                    Orders
                  </p>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/product" className="nav-link">
                  <i class="nav-icon fab fa-product-hunt" />
                  <p>
                    Product
                  </p>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/productcategory" className="nav-link">
                  <i class="nav-icon fab fa-product-hunt" />
                  <p>
                    Product Category
                  </p>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/productsubcategory" className="nav-link">
                    <i class="nav-icon fab fa-stripe-s"></i>
                    <p>Sub Category</p>
                </Link>
              </li>
              <li className="nav-item">
                <Link to={'/static-pages'} className="nav-link">
                <i class="nav-icon fas fa-file-alt" />
                  <p>
                    Static pages
                  </p>
                </Link>
              </li>
              <li className="nav-item">
                <Link to={'/contactUS'} className="nav-link">
                <i class="nav-icon fas fa-address-book" />
                  <p>
                    Contact US
                  </p>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/shopcategory" className="nav-link">
                  <i class="nav-icon fas fa-store" />
                  <p>
                    Shop Category
                  </p>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/transactions" className="nav-link">
                <i class="nav-icon fas fa-money-check-alt" />
                  <p>
                    Transactions
                  </p>
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </aside>
  )
}
export default AdminLtSide