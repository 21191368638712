import React, { useEffect } from "react";
import {useHistory} from "react-router-dom";
import { logout } from "../../redux/actionCreators/authenticateActions";
import {Button} from "reactstrap"
import 'bootstrap/dist/css/bootstrap.css';
import { useDispatch } from "react-redux";
import './index.css'

const Navbar = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const loggedin = localStorage.getItem('userData')
  useEffect(() => {
  },[loggedin])

  const handleLogout = () => {
    localStorage.clear()
    dispatch(logout())
    history.push('/sign-in')
  }
  const handleClick = (action) => {
    if(action === 'signup'){
      history.push('/sign-up')
      return
    }

    history.push('/sign-in')

  }
  const handleSetting=()=>{
  history.push('/admin-settings')
  }
  
  return(
    <div className="main">
      <div>
        ECOLIVE LOGO
      </div>
      <div style={{width:'300px'}}>
        {!loggedin ? 
        <ul style={{width: '300px'}}>


        
          <span  onClick={() => handleClick('signin')} style={{textDecoration: 'none', cursor: "pointer"}}>
           
          </span>
        </ul>
        : <React.Fragment>
            <Button onClick={handleSetting} outline color="primary">Settings</Button>
            
            <Button onClick={handleLogout} outline color="danger">Logout</Button>
          </React.Fragment>
        }
      </div>

    </div>
  )
}

export default Navbar
