import './index.css'
import _ from "lodash";
import ModalForm from "../Modal/index";
import React, { useState } from "react";
import ReactPaginate from 'react-paginate'
import { useDispatch } from "react-redux";
import { Table, Button } from "reactstrap";
import { deleteSubcategoryInitiate } from "../../redux/actionCreators/productSubcategoryActions";

function SubcategoryTable({tabledata,show,search,searchEdit}) {
  const [showmodal, setshowmodal] = useState(false)
  const [modaldata, setmodaldata] = useState({})
  const [pageNumber,setPageNumber] = useState(0)
  const imgConst = process.env.REACT_APP_IMAGE_PREFIX;

  
  const subcatogeryheaders = ["Name","Description", "Category", "SubCat Image"]
  
  const dispatch = useDispatch()
  const usersPerPage = 8
  const pageVisited = pageNumber*usersPerPage
  const diplayusers = tabledata.slice(pageVisited, pageVisited+usersPerPage)
  
  function handleSubcatDelete (event,id) {
    event.stopPropagation()
    dispatch(deleteSubcategoryInitiate(id))
  }
  
  
  const VisualRepresentation = () => {
    return(
      <Table responsive>
        <thead>
          <tr>
            { 
            subcatogeryheaders.map((itm,key)=>
            <th key={key}>{itm}&emsp;&emsp;</th>
            )}
            <td><Button color="success" onClick={()=> setshowmodal(true) }>Add</Button></td>
          </tr>
        </thead>
        <tbody>
        {diplayusers.map((itm,key) => 
          <tr key={key}>
            <td>
              {itm.name}
            </td>
            <td>
              {itm.description}&emsp;
            </td>
            <td>
              { typeof itm.categorys === 'string' ? itm.categorys : typeof itm.categorys === 'object' ?  itm.categorys[0].name :  itm.category ? itm.category : "" }
            </td>
            <td>
              <img width="100px" height="60px" src={itm.image11 ? itm.image11 : itm.image ? `imgConst${itm.image}` : ''} alt="error"/>
            </td>
            <td>
              <Button
                onClick={(event) => clickHandler(event,itm._id,'productsubcategory')}
                color="primary"
                style={{float: "left", marginRight:"10px", marginTop:"10px"}}
                >
                Edit
              </Button>
            </td>
            <td>
              <Button
                onClick={(event) => handleSubcatDelete(event,(itm._id || itm.id))}
                color={"danger"}
                style={{float: "left", marginRight:"10px", marginTop:"10px"}}
                >
                {"Delete"}
              </Button>
            </td>
        </tr>
        )}
        </tbody>
      </Table>
    )
  }
  
  const deactivateModal = () => {
    setmodaldata({})
    setshowmodal(false);
  };

  const clickHandler = (event,id) => {
    event.stopPropagation();
    const formData = _.find(tabledata, (tabledata) => tabledata._id === id);
    setmodaldata(formData);
    setshowmodal(!showmodal)
  }
  const changePage = ({selected}) => {
    setPageNumber(selected)
  }
  
  const pageCount = Math.ceil(tabledata.length / usersPerPage)
  
  return(
    <React.Fragment>
        {showmodal &&
          <ModalForm
            search={search}
            show={show}
            deactivate={deactivateModal}
            item={modaldata}
          />
        }
        <VisualRepresentation />
        <ReactPaginate
          previousLabel={"Prev"}
          nextLabel={"Next"}
          pageCount={pageCount}
          onPageChange={changePage}
          containerClassName={"paginationBttns"}
          previousLinkClassName={"previousBttn"}
          nextLinkClassName={"nextBttn"}
          disabledClassName={"paginationDisabled"}
          activeClassName={"paginationActive"}
        />
      </React.Fragment>
  )
}

export default SubcategoryTable;
