import {put} from "redux-saga/effects"
import axios from 'axios'
import {
  addVehicleCategorySuccess,
  editVehicleCategorySuccess,
  fetchVehicleCategorySuccess,
  deleteVehicleCategorySuccess,
} from "../actionCreators/vehicalCategoryActions";
import { makereq, toaster } from "./utils";

var qs = require('qs')

const baserurl = `${process.env.REACT_APP_BASE_URL}/vehicalCategory`

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 1000,
  
});

export function* addVehicleCategorySaga({payload}) {
  console.log(payload)
  try {
    var data = qs.stringify({
      'name': payload.name,
      'description': payload.description 
    });

    const headers= { 
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': `Bearer ${JSON.parse(localStorage.getItem('userData')).token}`
    }
    const res = yield makereq('vehicalCategory/','post',data,headers)
    const vehicleData = res.data
    yield put(addVehicleCategorySuccess({vehicleData}))
  } 
  catch (error) {
    console.log(error)
  }
}

export function* fetchVehicleCategorySaga({payload}) {
  try {
    const headers= { 
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': `Bearer ${JSON.parse(localStorage.getItem('userData')).token}`
    }
    // const res = yield instance.get('vehicalCategory')
    const res = yield makereq('vehicalCategory/','get',{},headers)
    console.log(res,"fetched results")
    const {data} = res
    console.log(data)
    yield put(fetchVehicleCategorySuccess({data}))
  } 
  catch (error) {
    error.message === 'invalid token...' ? toaster("error", 'please login again') : toaster('error', error.message)
    console.log(error)
  }
}

export function* deleteVehicleCategorySaga({payload}) {
  try {
    console.log("saganinit")
    const headers = { 
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': `Bearer ${JSON.parse(localStorage.getItem('userData')).token}`
    }
    yield makereq(`vehicalCategory/${payload}`,'delete',{},headers)
    yield put(deleteVehicleCategorySuccess({payload}))
  } 
  catch (error) {
    console.log(error)
  }
}

export function* editVehicleCategorySaga({payload}) {
  console.log(payload)
  try {
    var data = qs.stringify({
      'name': payload.name,
      'description': payload.description 
    });
    const headers = { 
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': `Bearer ${JSON.parse(localStorage.getItem('userData')).token}`
    }

    // var config = {
    //   method: 'put',
    //   url: `${baserurl}/${payload.id}`,
    //   headers: { 
    //     'Content-Type': 'application/x-www-form-urlencoded',
    //     'Authorization': `Bearer ${JSON.parse(localStorage.getItem('userData')).token}`
    //   },
    //   data : data
    // };
    // yield call(axios, config)
    yield makereq(`vehicalCategory/${payload.id}`,'put',data,headers)
    yield put(editVehicleCategorySuccess({payload}))
  } 
  catch (error) {
    console.log(error)
  }
}