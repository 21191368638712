import { vehicalCategoryActions } from "../actionTypes";

const intitalState = {
  vehicleCategorylist : [],
  // vehicleCategorySearchList: [],
  shoplist: [],
  productlist: [],
};

export const vehicleCategoryReducer = (state = intitalState, action) => {
  const {payload,type} = action
  switch (type) {

    case vehicalCategoryActions.FETCH_VEHICLE_CATEGORY_INITIATE: {
      return {
        ...state,
      };
    }
    
    case vehicalCategoryActions.FETCH_VEHICLE_CATEGORY_SUCCESS:{
      return {
        ...state,
        vehicleCategorylist: payload.data,
      };
    }
    
    case vehicalCategoryActions.DELETE_VEHICLE_CATEGORY_INITIATE: {
      return {
        ...state,
      };
    }
    
    case vehicalCategoryActions.DELETE_VEHICLE_CATEGORY_SUCCESS:{
      const res = state.vehicleCategorylist.filter((itm) => itm._id !== payload.payload)
      return {
        ...state, vehicleCategorylist: res
      }
    }
    
    case vehicalCategoryActions.ADD_VEHICLE_CATEGORY_INITIATE: {
      return {
        ...state,
      };
    }
    
    case vehicalCategoryActions.ADD_VEHICLE_CATEGORY_SUCCESS:{
      const newObj = {
        _id: payload.vehicleData.id,
        name: payload.vehicleData.name,
        description: payload.vehicleData.description,
        createdAt: payload.vehicleData.createdAt
      }
      const newvehicleCategorylist = [...state.vehicleCategorylist,newObj]
      return {
        ...state,
        vehicleCategorylist: newvehicleCategorylist
      }
    }
    
    case vehicalCategoryActions.EDIT_VEHICLE_CATEGORY_INITIATE: {
      return {
        ...state,
      };
    }
    
    case vehicalCategoryActions.EDIT_VEHICLE_CATEGORY_SUCCESS:{
      const newObj = {
        _id: payload.payload.id,
        name: payload.payload.name,
        description: payload.payload.description
      }
      let index = state.vehicleCategorylist.findIndex( element => {
        if (element._id === newObj._id) {
          return true;
        }
      });
      const newArray = [...state.vehicleCategorylist];
      newArray.splice(index, 1, newObj);
      return {
        ...state,
        vehicleCategorylist: newArray
      }
    }

    default:
      return {
        ...state,
      };

  }
};
