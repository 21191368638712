import './index.css'
import Header from "./header";
import Search from "../Search";
import AdminLtSide from "./adminltsidebar";
import { makereq, toaster } from "../../redux/sagas/utils";
import React, { useEffect, useState } from "react"
import ShopCategoryTable from "../Tablee/shopcategory";
import {
  useDispatch,
  useSelector,
  batch
} from "react-redux";
import {
  editShopCategorySuccess,
  fetchShopCategoryInitiate,
  deleteShopCategorySuccess,
} from "../../redux/actionCreators/shopcategoryActions";
import { Button } from 'reactstrap';
import axios from 'axios';
var qs = require('qs')

const ShopcategoryDisplay = () => {
  const dispatch = useDispatch();
  const [search, setsearch] = useState('');
  const [searchBy, setSearchBy] = useState('name');
  const [searchRes,setsearchRes] = useState('')
  const shopcategorylist = useSelector(state => state.shopcategoryState.shopcategorylist)
  const token = `Bearer ${JSON.parse(localStorage.getItem('userData')).token}`
  const baseURL = process.env.REACT_APP_BASE_URL;


  const dropdownArray = [
    {
      name: 'Name',
      value: 'name'
    },
    // {
    //   name: 'ID',
    //   value: 'id'
    // },
  ]
  useEffect(() => {
    batch(()=>{
      dispatch(fetchShopCategoryInitiate());
    })
  },[])

  const handleEditSearch = async(newItem) => {
    console.log(newItem)
    var data = qs.stringify({
      'name': newItem.name,
      'description': newItem.description 
      },{ encode: false });
    const headers = { 
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': `Bearer ${JSON.parse(localStorage.getItem('userData')).token}`
    }
    const res = await makereq(`shopCategory/${newItem.id}`,'put',data,headers)
    console.log(res)
    const updateres = res.data
    editShopCategorySuccess({updateres})
    if(res.message === "Shop update Success."){
      const newObj = {
        _id: res.data.id,
        name: res.data.name,
        description: res.data.description
      }
      let index = searchRes.findIndex( element => {
        if (element._id === newObj._id) {
          return true;
        }
      });
      var newArray = [...searchRes];
      newArray.splice(index, 1, newObj);
      setsearchRes(newArray)
    }
    toaster('success',res.message)
  }

  const handleSubmit = () => {
    console.log(shopcategorylist)
    if(searchBy.trim() && search.trim()){
      if(searchBy === 'name'){
        const newArr = shopcategorylist.filter((itm)=> itm.name === search)
        console.log(newArr)
        if(newArr.length){
          setsearchRes(newArr)
          return
        }
      }else if(searchBy === 'id'){
        const newArr = shopcategorylist.filter((itm)=> itm._id === search)
        if(newArr.length){
          setsearchRes(newArr)
          return
        }
      }
      toaster("success","could not find anything for your search")
      return
    }
  }

  const handleCancel = () => {
    setsearchRes('')
    setsearch('')
  }

  const TableContainer = () => {
    return(
      <div className="content">
        {searchRes &&
          <div style={{display: 'flex', justifyContent: 'space-between', lineHeight: '15px', marginRight: '3%'}}>
            <p>Search results</p>
            <Button color="primary" onClick={handleCancel}>Go Back</Button>
          </div>
        }
        <div className="container-fluid">
          {searchRes &&
            <ShopCategoryTable
              show={"shopcategory"}
              tabledata={searchRes}
              search={true}
              searchEdit={(id) => handleEditSearch(id)}
            />
          }
          {
            !searchRes &&
            <ShopCategoryTable
              show={"shopcategory"}
              tabledata={shopcategorylist && shopcategorylist.length ? shopcategorylist : []}
            />
          }
        </div>
      </div>
    )
  }

  return(
    <div class="wrapper">
    <Header />
    <AdminLtSide />
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
        <div className="row mb-2">
          <div className="col-sm-6">
            <h1 className="m-0">Shop Category</h1>
          </div>
          <div className="col-sm-3">
            <Search
              dropdown={false}
              inputstate={search}
              dropdownchange={(value) => setSearchBy(value)}
              dropdownarray={dropdownArray}
              inputchange={(value) => setsearch(value)}
              dropdownstate={searchBy}
              handleSubmit={handleSubmit}
            />
          </div>
        </div>
        </div>
        </div>
        <TableContainer />
      </div>
    </div>
  )
}
export default ShopcategoryDisplay