import './index.css'
import Header from "./header"
import Search from '../Search'
import AdminLtSide from "./adminltsidebar"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from 'react-redux'
import {
  Row,
  Col,
  Form,
  Input,
  FormGroup,
  Container,
  Button,
} from "reactstrap"
import StaticPageTable from '../Tablee/staticPageTable'
import {
  stringvalidator,
  toaster
} from '../../redux/sagas/utils'
import {
  addstaticPageInitiate,
  editstaticPageInitiate,
  fetchstaticPageInitiate
} from '../../redux/actionCreators/staticPageActions'
import { useHistory } from 'react-router'

const StaticPages = () => {

  const dispatch = useDispatch()
  const [mode,setmode] = useState(false)
  const [prop,setprop] = useState(false)
  const [search, setsearch] = useState('');
  const [searchBy, setSearchBy] = useState('name')
  const [addEdit ,setAddEdit] = useState(false)
  const [searchRes,setsearchRes] = useState('')
  const {staticpagelist} = useSelector(state => state.staticpageState)
  
  const dropdownArray = [
    {
      name: 'Name',
      value: 'name'
    },
    {
      name: 'Subcat Id',
      value: 'subCatId'
    },
    {
      name: 'Cat Id',
      value: 'catId'
    },
    {
      name: 'Cat Name',
      value: 'catname'
    }
  ]
  
  useEffect (() => {
    dispatch(fetchstaticPageInitiate())
  }, [])
  
  const handleSubmit = () => {
    if(search.trim()){
      const newarr = staticpagelist.filter((itm) => itm.title === search)
      if(newarr.length){
        setsearchRes(newarr)
        return
      }
      toaster("error","could not find your searching category")
      return
    }
  }

  const SearchData = () => {
    return(
      <div className="content">
        <div className="container-fluid">
          <div style={{display: 'flex', justifyContent: 'space-between', lineHeight: '15px', marginRight: '2%'}}>
            <p>Search Results</p>
            <Button color="primary" style={{paddingop: '4px',paddingBottom: '4px'}} onClick={() => setsearchRes('')}>Go Back</Button>
          </div> 
          <StaticPageTable show={"staticPage"} clic={editmode} tabledata={searchRes}/>
        </div>
      </div>
    )
  }

  const NormalData = () => {
    const history = useHistory()
    return(
      <div className="content">
        <div style={{display: 'flex', justifyContent: 'flex-end', marginRight: '3%'}}>
          <Button color="success" onClick={() => history.push('/add-static-content')}>
            Add Page
          </Button>
        </div>
        <div className="container-fluid">  
          <StaticPageTable
            clic={editmode}
            show={"staticPage"}
            tabledata={staticpagelist && staticpagelist.length ? staticpagelist : []}
          />
        </div>
      </div>
    )
  }
  
  function editmode(gotprops){
    setmode(true)
    setprop(gotprops)
    setAddEdit(true)
  }
  
  const Important = () => {
    return(
      <span style={{color: 'red'}}> * </span>
    )
  }

  const AddStaticContent = ({props,mode}) => {
    const [pageTitle, setPageTitle] = useState('')
    const [metaKeyword, setmetaKeyword] = useState('')
    const [metaKeywordDescription, setmetaKeywordDescription] = useState('')
    const [pageDescription,setPagedescription] = useState('')
    const [important, setImportant] = useState(false)
    const [id,setID] = useState('')
    const handlecancel = () => {
      setID('')
      setmode(false)
      setprop(false)
      setPageTitle('')
      setmetaKeyword('')
      setAddEdit(!addEdit)
      setPagedescription('')
      setmetaKeywordDescription('')
    }

    const handleAddSubmit = () => {
      const data = {
        id: id,
        title: pageTitle,
        metaKeyword: metaKeyword,
        description: pageDescription ,
        metaDescription: metaKeywordDescription,
      }
      if(props && mode ){
        dispatch(editstaticPageInitiate(data))
        handlecancel()
        return
      }
      dispatch(addstaticPageInitiate(data))
      handlecancel()
      setAddEdit(!addEdit)
    }

    useEffect(() => {
      if(mode && props){
        setID(prop._id)
        setPageTitle(props.title)
        setmetaKeyword(props.metaKeyword)
        setPagedescription(props.description)
        setmetaKeywordDescription(props.metaDescription)
      }
    },[])

    const handlevalidate = (e) => {
      e.preventDefault();
      if(stringvalidator(pageTitle) && stringvalidator(metaKeyword) && stringvalidator(metaKeywordDescription) &&  stringvalidator(pageDescription)){
        handleAddSubmit()
        return
      }
      setImportant(true)
      toaster("error","Please fill the * marked field")
    }

    return(
      <div>
        <div style={{width: '100%',display: 'flex',justifyContent: 'flex-end',marginRight: '3%'}}>
          <Button color="primary" onClick={console.log("canceel")}>Cancel</Button>
        </div>
        <Form>
          <Container>
          <FormGroup>
            <Row xs="2">
              <Col>
                Page Title :
                {important && <Important />}
              </Col>
            </Row>
            <Input value={pageTitle} onChange={(e) => setPageTitle(e.target.value)} />
          </FormGroup>
          <Row xs="2">
            <Col>
              Meta Keyword : {important && <Important />}
            </Col>
            <Col>
              <textarea
                value={metaKeyword}
                style={{width: '100%'}}
                onChange={(e) => setmetaKeyword(e.target.value)}
              />
            </Col>
          </Row>
          <Row xs="2">
            <Col>
              Meta Description : {important && <Important />}
            </Col>
            <Col>
              <textarea
                style={{width: '100%'}}
                value={metaKeywordDescription}
                onChange={(e) => setmetaKeywordDescription(e.target.value)}/>
            </Col>
          </Row>
          <Row xs="2">
            <Row>
              <Col>
              Page Description :
              {important && <Important />}
              </Col>
            </Row>
          </Row>
          <Row xs="2">
            <textarea
              value={pageDescription}
              onChange={(e) => setPagedescription(e.target.value)}
              style={{minHeight: '200px', width: '98%', margin: '10px'}}
            />
          </Row>
          <div style={{display: 'flex', justifyContent: 'center'}}>
            <button onClick={handlevalidate}>Submit</button>
          </div>
          </Container>
        </Form>
      </div>
    )
  }

  return(
    <div class="wrapper">
    <Header />
    <AdminLtSide />
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
        <div className="row mb-2">
          <div className="col-sm-6">
            <h1 className="m-0">Static Pages</h1>
          </div>
          <div className="col-sm-3">
            <Search
              dropdown={false}
              inputstate={search}
              dropdownstate={searchBy}
              handleSubmit={handleSubmit}
              dropdownarray={dropdownArray}
              inputchange={(value) => setsearch(value)}
              dropdownchange={(value) => setSearchBy(value)}
            />
          </div>
        </div>
        </div>
        </div>
        <div className="content">
          <div className="container-fluid">  
            <div className="row mb-2">
              {
                addEdit
                ? <AddStaticContent props={prop} mode={mode} />
                : searchRes.length > 0 
                ? <SearchData />
                : <NormalData />
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StaticPages