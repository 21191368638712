import { useState } from "react";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

const Search = ({
  dropdown,
  dropdownarray,
  dropdownchange,
  dropdownstate,
  inputstate,
  inputchange,
  handleSubmit
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);

  const DropdownStyles = {
    minWidth: '100px',
    borderRadius: '0px',
    borderBottomLeftRadius: '5px',
    borderTopLeftRadius: '5px',
    outline: 'none',
    backgroundColor: '#198754'
  }
  const InputWithDropdownStyles = {
    borderRadius: '0px',
    outline: 'none'
  }
  const InputStyles = {
    borderRadius: '0px',
    borderBottomLeftRadius: '5px',
    borderTopLeftRadius: '5px',
    outline: 'none'
  }
  const ButtonStyles = {
    borderRadius: '0px',
    borderBottomRightRadius: '5px',
    borderTopRightRadius: '5px',
    backgroundColor: '#198754'
  }


  return(
    <div style={{display: 'flex'}}>
      {dropdown !== false && <Dropdown isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle caret style={DropdownStyles}>
          {dropdownstate || 'Search By'}
        </DropdownToggle>
        <DropdownMenu container="body">
          {dropdownarray.map((item,key) => (
            <DropdownItem onClick={() => dropdownchange(item.value)} key={key}>{item.name}</DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>}
      <input style={dropdown === false ? InputStyles : InputWithDropdownStyles} type="text" value={inputstate} placeholder={ dropdownstate === 'lat,long' ? 'Lat,Long':"SEARCH"} onChange={(e) => inputchange(e.target.value)} />
      <Button style={ButtonStyles} onClick={handleSubmit}><i class="fab fa-searchengin">Search</i></Button>
    </div>
  )
}
export default Search