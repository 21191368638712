import { productActions, shopActions, vehicleActions, staticPageActions } from "../actionTypes";

export const addVehicleInitiate = (data) => {
  return {
    type: vehicleActions.ADD_VEHICLE_INITIATTE,
    payload: data,
  };
};

export const addVehicleSuccess = (data) => {
  return {
    type: vehicleActions.ADD_VEHICLE_SUCCESS,
    payload: data,
  };
};

export const deleteVehicleIntiate = (data) => {
  return {
    type: vehicleActions.DELETE_VEHICLE_INITIATE,
    payload: data,
  };
};

export const deleteVehicleSuccess = (data) => {
  return {
    type: vehicleActions.DELETE_VEHICLE_SUCCESS,
    payload: data,
  };
};

export const fetchVehicleInitiate = (data) => {
  return {
    type: vehicleActions.FETCH_VEHICLE_INITIATE,
    payload: data,
  };
};

export const fetchVehicleSuccess = (data) => {
  return {
    type: vehicleActions.FETCH_VEHICLE_SUCCESS,
    payload: data,
  };
};

export const editVehicleInitiate = (data) => {
  return {
    type: vehicleActions.EDIT_VEHICLE_INITIATE,
    payload: data,
  };
};

export const editVehicleSuccess = (data) => {
  return {
    type: vehicleActions.EDIT_VEHICLE_SUCCESS,
    payload: data,
  };
};
export const fetchProductInitiate = (data) => {
  return {
    type: productActions.FETCH_PRODUCT_INITIATE,
    payload: data,
  };
};

export const fetchProductSuccess = (data) => {
  return {
    type: productActions.FETCH_PRODUCT_SUCCESS,
    payload: data,
  };
};
export const fetchShopInitiate = (data) => {
  return {
    type: shopActions.FETCH_SHOP_INITIATE,
    payload: data,
  };
};

export const fetchShopSuccess = (data) => {
  return {
    type: shopActions.FETCH_SHOP_SUCCESS,
    payload: data,
  };
};

export const fetchPrivacyPolicyInitiate = (data) => {
  return {
    type: staticPageActions.FETCH_PRIVACY_POLICY_INITIATE,
    payload: data,
  };
};
export const fetchPrivacyPolicySuccess = (data) => {
  return {
    type: staticPageActions.FETCH_PRIVACY_POLICY_SUCCESS,
    payload: data,
  };
};

export const fetchTermsOfUseInitiate = (data) => {
  return {
    type: staticPageActions.FETCH_TERMS_OF_USE_INITIATE,
    payload: data,
  };
};

export const fetchTermsOfUseSuccess = (data) => {
  return {
    type: staticPageActions.FETCH_TERMS_OF_USE_SUCCESS,
    payload: data,
  };
};