import { settingsActions } from "../actionTypes";

const intitalState = {
  settingsData: {}
};

export const settingsReducers = (state = intitalState, action) => {
  const {payload,type} = action
  switch (type) {
    case settingsActions.FETCH_SETTINGS_INITIATE: {
      return {
        ...state,
      };
    }
    case settingsActions.FETCH_SETTINGS_SUCCESS: {
      const {data} = payload
      const newObj = {
        name: data[0].name,
        email: data[0].email,
        password: data[0].password,
        sitename: data[0].sitename,
        smtpemail: data[0].smtpemail,
        smtppassword: data[0].smtppassword,
        _id: data[0]._id
      }
      return {
        ...state,
        settingsData: newObj
      };
    }
    case settingsActions.EDIT_SETTINGS_INITIATE: {
      return {
        ...state,
      };
    }
    case settingsActions.EDIT_SETTINGS_SUCCESS:{
      return {
        ...state,
      }
    }
// ----------------------------product list fetch actions-----------------------------
    default:
      return {
        ...state,
      };
  }
};
